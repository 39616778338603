/*----------  Placeholders  ----------*/

@mixin placeholder($color: $black-color, $opacity: 1) 
{
	&::-moz-placeholder 
	{
		color: $color;

		@include transition;
		@include opacity($opacity);
	}
	
	&:-ms-input-placeholder
	{ 
		color: $color;

		@include transition;
		@include opacity($opacity);
	}

	&::-webkit-input-placeholder
	{
		color: $color;

		@include transition;
		@include opacity($opacity);
	}
}