/*----------  Index View  ----------*/
.home-page {
	.navbar-absolute {
		top: 70px;
	}

	.navbar-fixed {
		top: 0;
	}

	@include media-breakpoint-down(md) {
		.navbar-absolute {
			top: 0;
		}
	}

	.banner-container
	{
		height: 100vh;
	}
}


.banner-container
{
    height: 100vh;

	@include position;
	@include background-defaults;

	&:before
	{
		content: '';
        @include position(absolute, -1,0,0);
        height: 101%;
        width: 100%;
        object-fit: contain;

		// background: rgb(13,19,84); /* Old browsers */
		// background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.55), rgba(13, 19, 84, 0.34) 64%, rgba(13, 19, 84, 0) 79%, rgba(13, 19, 84, 0)); /* FF3.6-15 */
		// background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.55), rgba(13, 19, 84, 0.34) 64%, rgba(13, 19, 84, 0) 79%, rgba(13, 19, 84, 0)); /* Chrome10-25,Safari5.1-6 */
		// background: linear-gradient(to bottom, rgba(0, 0, 0, 0.55), rgba(13, 19, 84, 0.34) 64%, rgba(13, 19, 84, 0) 79%, rgba(13, 19, 84, 0)); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0d1354', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
    }

    .banner-wrapper{
        text-align: center;
        width: 100%;
        @include absolute-center;

        @include media-breakpoint-down(md){
            @include position(absolute, 2, $bottom: 0, $top: auto);
        }

        h1{
            @include font-s-64($weight-medium, 1.13, $white-color);
            margin-bottom: 40px;

            @include media-breakpoint-down(md){
                @include font-s-36($weight-medium, 1, $white-color);
                margin-bottom: 20px;
            }

            // @include media-breakpoint-down(sm){
            //     text-align: left;
            //     margin-left: 20px;
            // }
        }

        .banner-actions{
            text-align: center;

            // @include media-breakpoint-down(sm){
            //     text-align: left;
            //     margin-left: 10px;
            // }

            a.btn{
                margin: 0 10px 40px;

                @include media-breakpoint-down(xs){
                    margin: 0 10px 10px;
                }
            }

            .icon{
                margin-right: 16px;
            }

		}

		// @include media-breakpoint-down(sm)
		// {
		// 	height: auto;
		// }
    }

    .smooth-scroll{
        // @include position(absolute, 2, $left:50%, $bottom:10px);
        // @include transform(translateX(-50%));
        @include absolute-center(x){
            bottom: 10px;
            top : auto;
        };

        @include media-breakpoint-down(md){
            display: none;
        }
	}

	&.layout-secondary
	{
		.banner-wrapper
		{
			.banner-actions
			{
				.btn
				{
					@include media-breakpoint-down(xs)
					{
						display: block;
						margin: 0;
						margin-right: 10px;

						@include flex(1);

						&:last-child
						{
							margin: 0;
						}
					}

					@include media-breakpoint-down(xs)
					{
						margin: 0;
						padding: 12px 10px;
						margin-right: 10px;
						letter-spacing: 1px;
						font-size: rem-calc(9);

						&:last-child
						{
							margin: 0;
						}
					}
				}

				@include media-breakpoint-down(xs)
				{
					@include display-inline-flex;
				}
			}

			@include media-breakpoint-down(xs)
			{
				height: auto;
				padding: 0 20px;
				text-align: left;

				@include transform(none);
				@include position(absolute, 5, $top: auto, $bottom: 40px, $left: 0, $right: auto);

				h1 {
					margin-bottom: 40px;
				}
			}
		}
	}
}

.review-wrapper{
    @include display-inline-flex(center, space-between);

    @include max-width(926px);

    // margin: 40px auto;
    height: 250px;

    @include media-breakpoint-down(sm){
		padding: 40px 0;

		@include flex-direction(column);
		height: auto;
    }

	.review-block
	{
        @include display-inline-flex(center, space-between);

		@include media-breakpoint-down(sm)
		{
			margin: 0;

            @include flex-direction(column);
			// margin: 10px 0;
        }

		.review-logo
		{
            margin-right: 45px;

			@include media-breakpoint-down(md)
			{
				margin-right: 30px;
			}

			@include media-breakpoint-down(sm)
			{
				margin: 0;
				margin-bottom: 20px;
				// margin: 10px auto;
            }
        }

		svg
		{
			max-width: 100%;
			height: 45px;
        }

		&.inverse
		{
			.review-logo
			{
                margin-right: 0;
				margin-left: 45px;

				@include order(1);

				@include media-breakpoint-down(sm)
				{
					margin-left: 0;

					@include order(0);
					// margin: 10px auto;
                }
            }

			.review-bar
			{
				text-align: right;

				@include order(0);

				@include media-breakpoint-down(sm)
				{
                    text-align: center;
				}

				.review
				{
					@include order(2);

					@include media-breakpoint-down(sm)
					{
						@include order(0);
						margin: 0;
						margin-bottom: 6px;
					}
				}
            }
        }

		.review
		{
			span
			{
				font-weight: $weight-bold;
            }

			@include font-s($font-md, $weight-light, 1.22, $brand-primary);

			@include media-breakpoint-down(sm)
			{
                text-align: center;
            }
		}

		.review-bar
		{
			.review
			{
				@include media-breakpoint-down(sm)
				{
					margin-bottom: 6px;
				}
			}

			@include media-breakpoint-down(sm)
			{
				@include display-flex(column);
			}
		}
    }

	.review-center
	{
        padding: 15px;
		margin: 0 80px;
        background-color: #19447a;

		@include max-width(170px);
        @include border-radius(100%);

		@include media-breakpoint-down(md)
		{
            margin : 0 20px;
        }

		@include media-breakpoint-down(sm)
		{
			// margin : 20px;
			margin: 40px 0;
        }
    }

}

.main-slider-container{
    @include position;

	.slider-title-wrapper
	{
		width: 100%;

		@include position(absolute, 11, 80px , 0);

		.main-links
		{
			list-style-type: none;

			@include display-inline-flex;

			li
			{
				margin-right: 50px;
				padding-bottom: 5px;

				a
				{
					letter-spacing: 1.2px;
					text-transform: uppercase;

					@include position;
					@include font-sub($font-xs, $weight-light, 1.33, rgba($white-color, 0.5));

					&:after
					{
						content: '';
						height: 2px;
						width: 100%;
						background: $white-color;
						transform-origin: left center;

						@include transition;
						@include pointer-events(none);
						@include transform(scaleX(0));
						@include position(absolute, 1, $bottom: -5px, $left: 0);
					}

					@include media-breakpoint-down(xs)
					{
						// margin: 10px 0;
					}
				}

				&.slick-current, &.active, &:hover
				{
					> a
					{
						color: $white-color;

						&:after
						{
							@include transform(scaleX(1));
						}
					}
				}

				&:last-child
				{
					margin: 0;
				}
			}

			@include media-breakpoint-down(xs)
			{
				// @include display-flex(column);
				@include justify-content(space-between);
			}
		}

		@include media-breakpoint-down(xs)
		{
			top: 20px;
		}
    }

	.slide-wrapper
	{
        height: 930px;
        padding-top: 80px;

        @include position;
		@include background-defaults;
        @include display-flex(center, flex-start);

		@include media-breakpoint-down(md)
		{
            height: 600px;
        }

		@include media-breakpoint-down(sm)
		{
            height: 500px;
            padding-top: 0;
        }

        &:before{
            content: '';
            height: 100%;
            width: 100%;
            // object-fit: contain;
            background-image: linear-gradient(226deg, rgba(13, 19, 84, 0.5), $brand-primary 66%);

			@include position(absolute, -1, 0, 0);
        }

        h1{
			@include font-s-64($weight-light, 1.13, $white-color);

			@include media-breakpoint-down(xs)
			{
				font-size: rem-calc(24);
			}
        }

        p{
			margin: 10px 0 55px 0;

            @include font-p(32px, $weight-light, 1.6, $white-color);

            @include media-breakpoint-down(sm){
                margin: 10px 0 25px 0;
                font-size: rem-calc(16);
			}

			@include media-breakpoint-down(xs)
			{
				margin-top: 10px;
				margin-bottom: 15px;
			}
		}

		.btn
		{
			@include media-breakpoint-down(xs)
			{
				font-size: rem-calc(9);
			}
		}

		.main-content
		{
			@include media-breakpoint-down(xs)
			{
				text-align: center;
			}
		}
    }
}


.sub-slider-wrapper{
    @include position;
    height: 560px;

    .slider-links{
        @include display-inline-flex;
        list-style-type: none;
        margin: 20px 0;
        max-width: 50%;
        margin-left: auto;

        @include media-breakpoint-down(md){
            // @include display-flex(column);
            flex-wrap: wrap;
        }

        @include media-breakpoint-down(sm){
            // @include display-flex(column);
            // flex-wrap: no-wrap;
        }

		li
		{
            margin: 5px 0;
			margin-right: 34px;

			.link-item
			{
				text-transform: uppercase;
				letter-spacing: 1.4px;
				padding: 12px 23px 12px 0;
				border: 2px solid transparent;
				border-radius: 30px;
				@include font-sub($font-sm, $weight-light, 1.43, $brand-primary);
				@include display-inline-flex;
				@include transition;
				@include position;

				@include media-breakpoint-down(md)
				{
					// padding: 6px 10px;
				}

				.icon-wrapper{
					margin-right: 0;
					max-width: 28px;
					@include opacity(0);
					@include transition;
					@include absolute-center(y){
						left: 0px;
					};

					svg {
						width: 100%;
					}

					@include media-breakpoint-down(xs)
					{
						@include transform(translateX(-50%));
						@include position(absolute, 5, 0, 50%);
					}
				}

				.link-text {
					white-space: nowrap;
				}

				@include media-breakpoint-down(xs)
				{
					border: 0;
					padding: 0 25px;
					font-size: rem-calc(9);

					@include border-radius(0);
				}
			}

			&.slick-current, &.active
			{
				> a
				{
					border-color: $brand-primary;
					padding-left: 70px;

					.icon-wrapper
					{
						left: 23px;

						@include opacity(1);

						@media (max-width: 1599px)
						{
							left: 10px;
						}

						@include media-breakpoint-down(xs)
						{
							left: 50%;
						}
					}

					@media (max-width: 1599px)
					{
						padding-left: 50px;
					}

					@include media-breakpoint-down(xs)
					{
						padding-top: 40px;
						padding-left: 25px;
					}
				}
			}

            &:last-child{
                margin-right: 0;
			}

			@media (max-width: 1599px)
			{
				margin-right: 15px;
			}

            @include media-breakpoint-down(md){
                margin: 10px 0;
                // width: 50%;
            }

            @include media-breakpoint-down(sm){
                margin: 10px 0;
                // width: 100%;
			}

			@include media-breakpoint-down(xs)
			{
				margin: 0;
				height: 120px;

				@include display-flex(column, center, center);
			}
		}

		@media (max-width: 1599px)
		{
			@include flex-wrap(wrap);
		}

		@include media-breakpoint-down(xs)
		{
			display: block;
			margin: 0;
			text-align: center;
		}
    }

    .slider-inner-media-wrapper {
        width: 42.3%;
        @include position(absolute, 2, $left: 0, $top: -230px);
        .media-wrapper{
            // @include position(absolute, 2, $left: 0, $top: 0);
            .media-container {
                @include position;
                // @include position(absolute, 2, 0, 0);
                // @include max-width(812px);

                .play{
                    @include absolute-center();
                    text-align: center;
                }

                .external-play {
                    .play-icon-wrapper {
                        border: 4px solid $white-color;
                        @include border-radius(999px);
                        padding: 35px;
                        margin-bottom: 20px;
                        .play-icon {
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 10.5px 0 10.5px 18px;
                            border-color: transparent transparent transparent $white-color;
						}

						@include media-breakpoint-down(xs)
						{
							padding: 20px;
							margin-bottom: 10px;
						}
                    }

                    p {
						white-space: nowrap;

						@include font-p($font-lg, $weight-regular, 1.25, $white-color);

						@include media-breakpoint-down(xs)
						{
							font-size: $font-xs;
						}
                    }
				}
            }

        }
    }

    .slider-inner-content-wrapper{
        max-width: 50%;
        margin-left: auto;
        .sub-slide-content{
            margin-top: 65px;

            @include media-breakpoint-down(md){
                margin-top: 30px;
			}

            h1 {
				@include font-s-46($weight-medium, 1.22, $brand-primary);

				@include media-breakpoint-down(xs)
				{
					font-size: rem-calc(24);
				}
            }

            p{
                @include font-p-20($weight-light, 1.4, $brand-primary);
                margin-top: 24px;
				margin-bottom: 40px;

				@include media-breakpoint-down(xs)
				{
					margin-top: 14px;
					line-height: 1.6;
					font-size: $font-xs;
					margin-bottom: 16px;
				}
			}

			.btn
			{
				@include media-breakpoint-down(xs)
				{
					font-size: rem-calc(9);
				}
			}
        }
	}

	@media (max-width: 1700px) {
		.slider-inner-media-wrapper {
            top: -180px;
        }
	}
	@media (max-width: 1600px)
	{
		.slider-inner-media-wrapper {
            top: -150px;
        }
	}
    @media (max-width: 1500px)
    {
		.slider-inner-content-wrapper{
			.sub-slide-content{
				margin-top: 30px;
				h1 {
					font-size: 30px;
				}
			}
		}
	}

    @media (max-width: 1400px)
    {
		.slider-inner-content-wrapper{
			.sub-slide-content{
				margin-top: 25px;
			}
		}
	}
    @media (max-width: 1439px)
    {
        .slider-inner-media-wrapper {
            width: 47%;
            top: -100px;
        }
	}
	@media (max-width: 1200px)
    {
		.slider-inner-content-wrapper{
			.sub-slide-content{
				margin-top: 0px;
				p {
					font-size: 18px;
				}
			}
		}
    }
    @include media-breakpoint-down(lg){
        height: auto;
        .slider-links {
            max-width: 100%;
            @include justify-content(space-between);
        }
        .slider-inner-wrapper {
            @include display-inline-flex(center, space-between);
        }
        .slider-inner-media-wrapper {
            @include reset-position;
        }
        .slider-inner-content-outer {
            width: 50%;
        }
        .slider-inner-content-wrapper{
            max-width: 100%;
            .sub-slide-content{
                margin-top: 40px;
            }
        }
    }

    @include media-breakpoint-down(sm){
        .slider-inner-wrapper {
            @include display-flex(column, center, space-between);
        }
        .slider-inner-media-wrapper {
            width: 100%;
        }
        .slider-inner-content-outer {
            width: 100%;
        }
        .slider-inner-content-wrapper{
            max-width: 100%;
        }
	}

	@include media-breakpoint-down(xs)
	{
		margin-bottom: 40px;
	}
}

.statstics-container
{
	@include media-breakpoint-down(xs)
	{
		// background: $brand-danger;
	}
}

.statstics-wrapper{
    padding: 160px 0 193px;
    text-align: center;

	@media (max-width: 1500px)
    {
		padding: 80px 0 193px;
	}

	@media (max-width: 1330px)
    {
		padding: 80px 0 193px;
	}

	@media (max-width: 1199px)
    {
		padding: 80px 0px;
	}

    @include media-breakpoint-down(md){
        padding: 120px 0;
    }

    @include media-breakpoint-down(sm){
		padding: 0 0 40px;
		.col-sm-4 {
			margin-bottom: 30px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@include media-breakpoint-down(xs)
	{
		// padding-top: 105px;
		// padding-bottom: 25px;

		.stats-block
		{
			// margin-bottom: 80px;
		}
	}

    .heading-text{
		margin-bottom: 22px;

        @include font-s-64($weight-medium, 1.2, $brand-danger);

		@include media-breakpoint-down(sm)
		{
            line-height: 1.2;
			margin-bottom: 6px;
		}

		@include media-breakpoint-down(xs)
		{
			// color: $white-color;
			// font-size: rem-calc(36);
		}

    }
    .desc-text{
		@include font-p-16($weight-regular, 1.38, $brand-gray-01);

		@include media-breakpoint-down(xs)
		{
			// color: $white-color;
			// font-size: $font-sm;
		}
	}
}

.testimonial-container{

    padding: 160px 0 170px;

    @include position;
	@include bg-color($color-01);

    .top-content{
        margin-bottom: 160px;

		h4
		{
			letter-spacing: 2px;
			text-transform: uppercase;

			@include font-sub($font-lg, $weight-light, 1.3, $brand-primary);

			@include media-breakpoint-down(xs)
			{
				font-size: $font-xs;
				letter-spacing: 1.2px;
			}
		}

		@include media-breakpoint-down(md)
		{
            margin-bottom: 115px;
        }
    }

	.testimonial-block
	{
        margin: 0 auto;
		text-align: center;

		@include max-width(928px);

		.testimonial
		{
			@include font-p-30($weight-light, 1.4, $brand-primary);

			@include media-breakpoint-down(xs)
			{
				line-height: 1.6;
				font-size: $font-sm;
			}
        }

		.author
		{
			margin-top: 52px;

			@include font-p-16($weight-light, 1.19, $brand-primary);

            span{
                color: $brand-gray-01;
                margin-right: 10px;
            }

            svg{
                @include max-width(24px);
			}

			@include media-breakpoint-down(xs)
			{
				margin-top: 20px;
				font-size: $font-sm;
			}
		}

		@include media-breakpoint-down(xs)
		{
			margin-bottom: 120px;
		}
    }

    .testimonial-wrapper{
        @include position;
    }

    .slick-slider-dots-container{
        @include position(absolute, 2, $top: 0, $right: 0, $left: auto );

        @include media-breakpoint-down(xs){
            @include position;
        }
	}

	.slick-dots {
		@include reset-list;
		@include display-inline-flex;
		@include transition;

		li {
			.slick-dots-circle {
				height: 12px;
				width: 12px;
				// background-color: $white-color;
				margin: 5px;
				border: 2px solid rgba($brand-gray-04, 0.5);

				@include transition;
				@include border-radius(50%);
			}

			&.slick-active {
				.slick-dots-circle {
					width: 24px;
					@include border-radius(10px);
					border: 2px solid $brand-primary;
				}
			}
		}
	}

	.slick-dotted
	{
		.slick-dots
		{
			@include media-breakpoint-down(xs)
			{
				@include reset-position;
				@include justify-content(center);
			}
		}
	}

	&:before
	{
        content: '';
        height: 165px;
        width: 180px;
        background: url('../icons/right-quote-sign.svg') no-repeat center center;
        background-size: contain;

        //@include position(absolute, 2, 0, 0);
        @include absolute-center(x){
            top: 100px;
            z-index: 0;
        };

        @include media-breakpoint-down(md){
            height: 80px;
            width: 90px;
		}

		@include media-breakpoint-down(xs)
		{
			top: 60px;
			width: 64px;
			height: 57px;
		}
	}

	@include media-breakpoint-down(md)
	{
        padding: 80px 0;
    }

	@include media-breakpoint-down(xs)
	{
		text-align: center;
    }
}

.callout-container
{
    @include bg-color($white-color);

	.callout-wrapper
	{
        height: 355px;
        text-align: center;

		@include position;
        @include display-flex(column, center, center);

		h3
		{
			font-weight: $weight-medium;

			@include media-breakpoint-down(xs)
			{
				font-size: rem-calc(24);
			}
		}

		@include media-breakpoint-down(xs)
		{
			height: auto;
			padding-top: 40px;
			padding-bottom: 120px;
		}
    }

	.callout-actions
	{
        margin-top: 20px;

		ul
		{
			li
			{
                margin: 0 12px;
                letter-spacing: 1.4px;

				@include font-sub($font-sm, $weight-regular, 1.2, $brand-gray-01);

				.btn
				{
					@include media-breakpoint-down(xs)
					{
						font-size: rem-calc(9);
					}
				}

				&.divider
				{
					@include media-breakpoint-down(xs)
					{
						display: none;
					}
				}

				@include media-breakpoint-down(lg)
				{
					margin: 0;
					margin-bottom: 15px;

					&:last-child
					{
						margin: 0;
					}
                }
			}

			@include media-breakpoint-down(lg)
			{
                @include display-flex(column);
            }
		}
    }

	.callout-bg
	{
        @include position(absolute, -1, $left:110px, $bottom:0);

		@include media-breakpoint-down(lg)
		{
            @include position(absolute, -1, $left:0px, $bottom:0);
        }

		@include media-breakpoint-down(md)
		{
			@include max-width(250px, false);
		}

		@include media-breakpoint-down(xs)
		{
			max-width: 160px;
		}
    }
}
