
.navbar-container
{
	width: 100%;
	@include transition(all 0.5s ease-in-out);
}

.navbar-wrapper
{
	@include position;
	// padding-top: 120px;
	@include transition(all 0.3s ease-out);
	transition-delay: 0.3s;
    .navbar-inner-wrapper
    {

		@include display-inline-flex(center, space-between);
		align-items: center;
		height: 120px;
	}

	.logo-wrapper
	{
		@include absolute-center;
		@include transition;

		svg
		{
			height: 100%;

			@include max-width(100%);
		}

		a
		{
			display: block;

			@include max-width(143px);

			@include media-breakpoint-down(md)
			{
				margin: 0;
			}
		}

		@include media-breakpoint-down(md)
		{
			margin: 0;
			padding: 15px 0;

			@include reset-position;
			// @include max-width(100%);

			@include display-flex(column, center);
		}
	}

    .nav-links
    {
		list-style-type: none;
		@include display-inline-flex(center, space-between);

		a {
			@include font-sub($font-sm, $weight-regular, 1.43, $white-color);
			letter-spacing: 1.4px;
			margin: 0 25px;
			text-transform: uppercase;
			@include transition;

			// &:hover, &:active, &:focus
            // {
			// 	color: $white-color;
            // }

            // &:not(.btn) {
            //     &:hover, &:active, &:focus
            //     {
            //         color: rgba($color: $white-color, $alpha: 0.5);
            //     }
            // }

			@include media-breakpoint-down(lg){
				margin: 0 10px;
			}
		}
    }

    .navbar-inner-wrapper {
        &:hover, &:active, &:focus, &.active {
            .nav-links {
                li {
                    a {
                        color: rgba($color: $white-color, $alpha: 0.5);
                        &.link-btn {
                            color: rgba($color: $white-color, $alpha: 1);
                        }
                    }
                }
            }
        }
        .nav-links {
            li {
                &:hover, &:active, &:focus, &.active {
                    a {
                        color: rgba($color: $white-color, $alpha: 1);
                    }
                }
            }
        }
    }

    @media (max-width: 1439px)
    {
        .nav-links{
            a {
                margin: 0 10px;
            }
        }
	}

	@include media-breakpoint-down(lg)
	{
		height: 110px;
	}
}

.navbar-absolute{
    @include position(absolute, 999, $top:0);
    .navbar-wrapper
    {
        .navbar-inner-wrapper {
            &:hover, &:active, &:focus, &.active {
                .nav-links {
                    li {
                        a {
                            color: rgba($color: $white-color, $alpha: 0.5);
                            &.link-btn {
                                color: rgba($color: $white-color, $alpha: 1);
                            }
                        }
                    }
                }
            }
            .nav-links {
                li {
                    &:hover, &:active, &:focus, &.active {
                        a {
                            color: rgba($color: $white-color, $alpha: 1);
                        }
                    }
                }
            }
        }
    }
}

.navbar-solid{
	background-color: rgba($brand-primary, 0.9);
	@include position(absolute, 999, $top:0);
}

.navbar-fixed{
	position: fixed;
	top: 0;
	background-color: rgba($brand-primary, 0.9);
}

// .navbar-bg-gradient{
// 	.navbar-wrapper{
// 		.nav-links{
// 			a{
// 				@include opacity(0.5);
// 				@include transition;

// 				&:hover, &:active, &:focus
//                 {
// 					color: $white-color;
// 					@include opacity(1);
//                 }


// 			}
// 		}
// 	}
// }

@include media-breakpoint-down(md)
{
	.navbar-container
	{
		// @include position(fixed, 5);
		.navbar-wrapper
		{
			// height: 100vh;

			&.navbar-responsive
			{
				height: 100vh;
				@include transition;
				transition-delay: 0s;
				.navbar-inner-wrapper
				{
                    @include opacity(1);
					z-index: 6;
                    @include pointer-events(all);
				}
			}

			// svg
			// {
			// 	@include max-width(70%);
			// 	height: 70%;
			// 	padding: 10px;
			// }

			.navbar-inner-wrapper
			{
				width: 100%;
                height: 100%;

				@include opacity(0);
                @include pointer-events(none);
				@include flex-direction(column);
				@include justify-content(center);
				@include transition(all 0.5s ease-in-out);

				@include absolute-center;
				position: fixed;

				.nav-links
				{
					justify-content: center;

					@include flex-direction(column);

					a
					{
						margin: 18px auto;
						text-align: center;
						font-size: 20px;
						@include display-inline-flex(column, flex-start);
					}

					.link-btn
					{
						color: $white-color;
						letter-spacing: 1px;
						white-space: nowrap;
						word-break: keep-all;
						// font-size: rem-calc(9);
						background-color: $brand-danger;
					}
				}
			}
		}

		&.navbar-fixed {
			background-color: rgba($brand-primary, 0.9);
		}
	}
}

.hamburger
{
	display: none;
}

@include media-breakpoint-down(md)
{
	.hamburger
	{
		display: block;
		@include transition;
		@include position(absolute, 9, $top: 30px, $right: -15px);

		&.is-active
		{
			.hamburger-inner
			{
				background-color: $white-color;

				&:after, &:before
				{
					background-color: $white-color;
				}
			}
		}

		.hamburger-inner
		{
			background-color: $white-color;

			&:after, &:before
			{
				background-color: $white-color;
			}
		}
	}
}

@include media-breakpoint-down(sm)
{
	.navbar-container
	{
		.navbar-wrapper
		{
			.navbar-inner-wrapper
			{
				.nav-links
				{
					a
					{
						margin: 10px auto;
						font-size: 14px;
					}
				}
			}
		}
	}
}

@include media-breakpoint-down(xs)
{
	.navbar-container
	{
		.navbar-wrapper
		{
			.navbar-inner-wrapper
			{
				.nav-links
				{
					a
					{
						margin: 10px auto;
						font-size: 14px;
					}
				}
			}
		}
	}
}
