/*----------  Body  ----------*/

body {
    @include font-p($font-base, $weight-regular, 1.2, $brand-dark);
	// @include font-p-16($font-base, $weight-regular, 1.2, $brand-dark);
	overflow-x: hidden;

    &.overflow-hidden {
        overflow: hidden;
	}
}

.container-md {
    @include extend-container(1250px, 60px);
}
.container-lg {
    @include extend-container(1400px, 60px);
}
.container-xl {
    @include extend-container(1560px, 60px);
}

.no-hand {
	cursor: default !important;
}

// .row{
//     &.no-padding{
//         .col
//     }
// }
.btn {
    @include backface-visibility(unset !important);
    &:hover, &:active, &:focus
    {
        box-shadow: none;
    }

    &.btn-xs {
        font-size: $font-xs;
    }
}

.background-defaults{
	@include background-defaults;
}

.opacity-0 {
	@include opacity(0)
}

.btn-outlined-danger {
    border-radius: 999px;
    border: solid 2px $brand-danger;
    padding: 12px 24px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    @include font-sub($font-sm, $weight-regular, 1.4, $white-color);

    svg {
        margin-right: 10px;
        path {
            @include transition;
            stroke: $white-color;
        }
    }

    &:hover, &:active, &:focus
    {
        background-color: $brand-danger;
        border: solid 2px $brand-danger;
        color: $white-color;

        svg {
            path {
                stroke: $brand-danger;
            }
            line {
                stroke: $white-color;
            }
        }
	}

	@include media-breakpoint-down(xs)
	{
		font-size: 10px;
		padding: 12px 20px;
	}
}

.btn-outlined-primary {
    border-radius: 999px;
    border: solid 2px $brand-primary;
    padding: 12px 24px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    @include font-sub($font-sm, $weight-regular, 1.4, $brand-primary);

    svg {
        margin-right: 10px;
        path {
            @include transition;
            stroke: $white-color;
        }
    }

    &:hover, &:active, &:focus
    {
        background-color: $brand-primary;
        border: solid 2px $brand-primary;
        color: $white-color;

        svg {
            path {
                stroke: $brand-primary;
            }
            line {
                stroke: $white-color;
            }
        }
    }
}

.btn-outlined-white {
    border-radius: 6px;
    border: solid 2px $white-color;
    padding: 12px 24px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    @include font-sub($font-sm, $weight-regular, 1.4, $white-color);

    svg{
        margin-right: 10px;
    }

    &.btn-sm {
        padding: 8px 14px;
        line-height: 1;
        font-size: $font-xs;
    }

    &:hover, &:active, &:focus
    {
        background-color: $brand-danger;
        border: solid 2px $brand-danger;
        color: $white-color;
    }
}

// .on-hover-primary {
//     &:hover, &:active, &:focus
//     {
//         color: $brand-primary !important;
//     }
// }
// .on-hover-white {
//     &:hover, &:active, &:focus
//     {
//         color: $white-color !important;
//     }
// }

.btn-text-primary, .text-primary {
    color: $brand-primary;
}

.btn-p-filled {
    background-color: $brand-primary;
    border-color: $brand-primary;
}

.btn-danger-filled {
    background-color: $brand-danger;
    border-color: $brand-danger;
    @include transition;

    svg{
        margin-right: 20px;
    }

    &:hover, &:active, &:focus
    {
        // background-color: transparent;
        background-color: $white-color;
        border-color: $brand-danger;
        color: $brand-primary;

        svg{
            fill: $brand-danger;
            @include transition;

            path, line {
                stroke: $brand-danger;
            }
        }
    }
}

.btn-theme-white {
    background-color: $white-color;
    border: solid 2px $white-color;
    border-radius: 100px;
    padding: 12px 24px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    @include font-sub($font-sm, $weight-regular, 1.4, $brand-primary);

    svg path {
        @include transition;
    }

    &:hover, &:active, &:focus
    {
        background-color: $brand-danger !important;
        border: solid 2px $brand-danger !important;
        @include text-color($white-color);

        svg path {
            stroke: $white-color;
        }
    }
}

.btn-theme-gray {
    background-color: $brand-gray-01;
    border: solid 2px $brand-gray-01;
    border-radius: 100px;
    padding: 12px 24px;
    text-transform: uppercase;
    width: 100%;
    @include max-width(296px);
    letter-spacing: 1.4px;
    @include font-sub($font-sm, $weight-regular, 1.4, $white-color);

    &:hover, &:active, &:focus
    {
        background-color: $brand-danger;
        border: solid 2px $brand-danger;
        color: $white-color;
    }

}

.btn-primary-solid{
    background-color: $brand-primary;
    border: solid 2px $brand-primary;
    color: $white-color;
    @include font-sub($font-sm, $weight-regular, 1.33, $white-color);
    padding: 16px 24px;
    text-transform: uppercase;
    letter-spacing: 1.4px;


    &:hover, &:active, &:focus
    {
        background-color: $brand-danger;
        border: solid 2px $brand-danger;
        color: $brand-primary;
    }
}

.btn-rounded {
    border-radius: 100px;
}

.btn-large{
    padding: 40px 140px;
    @include media-breakpoint-down(lg) {
        padding: 12px 24px;
    }
}
.btn-back-white{
    @include font-sub(rem-calc(14px), $weight-medium, 1.13, $white-color);
    text-transform: uppercase;

    &:hover{
        color: $brand-danger;
    }
}


/* Utilities classes */
.rotate-90 {
    @include rotate(90deg);
}

.rotate-180 {
    @include rotate(180deg);
}

/* Background color classes */
.gray-wrapper {
    background-color: $brand-gray-02;
}

.with-padding {
    padding: 120px 0;
}
.with-paddingb {
    padding: 0 0 120px;
}
.with-paddingt {
    padding: 120px 0 0;
}

@include media-breakpoint-down(sm) {
    .with-padding {
        padding: 30px 0;
    }
    .with-paddingb {
        padding: 0 0 30px;
    }
    .with-paddingt {
        padding: 30px 0 0;
    }
}


.white-wrapper {
    background-color: $white-color;
}

.bg-white {
    background-color: $white-color;
}

.bg-gray{
    background-color: $brand-gray-02;
}

.bg-gray-radius{
    background-color: $brand-gray-02;
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 50%;
}

.on-hover-shadow {

    &:hover, &:active, &:focus
    {
        box-shadow: 0 16px 30px 0 rgba($color: $brand-gray-03, $alpha: 0.4);
    }
}

/* Image radius classes */
.bl-radius {
    border-bottom-left-radius: 100px;
    overflow: hidden;
}

.tr-radius {
    border-top-right-radius: 100px;
    overflow: hidden;
}

//jquery circle progress custom
.circle-wrapper {
    @include position;

    .circle-text {
        @include absolute-center;
        text-align: center;

        .percentage {
            @include font-s-24($weight-regular, 1.33, $white-color);
        }

        .sold {
            @include font-s-12($weight-regular, 1.67, $white-color);
            letter-spacing: 1.2px;
            text-transform: uppercase;
        }
    }
}
.ucase{
    text-transform: uppercase;
}

.mb-20{
    margin-bottom: 20px;
}
.mb-24 {
    margin-bottom: 24px;
}

.mb-80 {
    margin-bottom: 80px;
    @include media-breakpoint-down(md) {
        margin-bottom: 40px;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 25px;
    }
}

.mt-120{
    margin-top: 120px;
    @include media-breakpoint-down(md) {
        margin-top: 60px;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 30px;
    }
}

.mb-120 {
    margin-bottom: 120px;
    @include media-breakpoint-down(md) {
        margin-bottom: 60px;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
    }
}

.ptb-120{
    padding-top: 120px;
    padding-bottom: 120px;
    @include media-breakpoint-down(md) {
        padding-top: 60x;
        padding-bottom: 60px;
    }
    @include media-breakpoint-down(sm) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
.pt-120 {
    padding-top: 120px;
    @include media-breakpoint-down(md) {
        padding-top: 60px;
    }
    @include media-breakpoint-down(sm) {
        padding-top: 30px;
    }
}
.pb-120 {
    padding-bottom: 120px;
    @include media-breakpoint-down(md){
        padding-bottom: 60px;
    }
    @include media-breakpoint-down(sm) {
        padding-bottom: 30px;
    }
}

.ribbon-wrapper, .sold-wrapper {
    @include position(absolute, 9, $top:20px, $left:-6px);
    @include display-flex(column, flex-start, flex-start);

    .ribbon {
        margin-bottom: 10px;
        padding-right: 20px;
        padding-left: 15px;
        height: 40px;
        @include position;
        @include display-flex(column, flex-start, center);

        span{
            width: 100%;
            @include position(absolute, -1, -7px, 0, 0, 0);

            svg{
				width: 150px;
				height: 47px;
            }
        }

        .tag-text {
            letter-spacing: 1.2px;
            white-space: nowrap;
            @include font-s-12($weight-bold, 1.33, $white-color);
        }

        &.yellow-ribbon {
            .tag-text {
                color: $brand-primary;
            }
        }

        // &.blue-ribbon {
        //     .tag-text {
        //         color: $white-color;
        //     }
        // }

        // &.green-ribbon {
        //     .tag-text {
        //         color: $white-color;
        //     }
		// }

		&:last-child{
			margin-bottom: 0;
		}
	}

	.opendays-ribbon {
		height: auto;
		padding: 0;

		text {
			letter-spacing: 1.2px;
            white-space: nowrap;
            @include font-s-12($weight-bold, 1.33, $white-color);
		}

		@include media-breakpoint-down(md)
		{
			svg {
				width: 250px;
			}
		}
	}
}

.sold-wrapper {
	@include position(absolute, 9, 0px, 0px, 0px, 0px);
	@include display-flex(column, center, center);
	&:before {
		content: '';
		@include position(absolute, 0, 0px, 0px, 0px, 0px);
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+80,000000+100&0.43+0,0+100 */
		background: -moz-linear-gradient(top,  rgba(0,0,0,0.43) 0%, rgba(0,0,0,0.09) 80%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,0.43) 0%,rgba(0,0,0,0.09) 80%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(0,0,0,0.43) 0%,rgba(0,0,0,0.09) 80%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6e000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

	}

	.ribbon {
		width: 100%;
		text-align: center;
		height: auto;

		span {
			top: 0;
			@include reset-position;
			svg{
				width: 240px;
				height: 50px;
            }
		}

		.tag-text {
			width: 100%;
			text-align: center;
			line-height: 45px;
			position: absolute;
			left: 0;
			font-size: 18px;
		}
	}

}
/* Banner Gradients */
.banner-gradient-01{

    &:before {
        background: rgba(0, 0, 0, 0.55);
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(13, 20, 82, 0.34) 64%, rgba(13, 20, 82, 0) 79%, rgba(13, 20, 82, 0) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.55)), color-stop(64%, rgba(13, 20, 82, 0.34)), color-stop(79%, rgba(13, 20, 82, 0)), color-stop(100%, rgba(13, 20, 82, 0)));
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(13, 20, 82, 0.34) 64%, rgba(13, 20, 82, 0) 79%, rgba(13, 20, 82, 0) 100%);
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(13, 20, 82, 0.34) 64%, rgba(13, 20, 82, 0) 79%, rgba(13, 20, 82, 0) 100%);
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(13, 20, 82, 0.34) 64%, rgba(13, 20, 82, 0) 79%, rgba(13, 20, 82, 0) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.55) 0%, rgba(13, 20, 82, 0.34) 64%, rgba(13, 20, 82, 0) 79%, rgba(13, 20, 82, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#0d1452', GradientType=0);
    }

}
.banner-gradient-02 {

    &:before {
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(13, 19, 84, 0.2)), color-stop(86%, rgba(13, 19, 84, 0.9)), to(#0d1354));
        background: -o-linear-gradient(top, rgba(13, 19, 84, 0.2), rgba(13, 19, 84, 0.9) 86%, #0d1354);
        background: linear-gradient(to bottom, rgba(13, 19, 84, 0.2), rgba(13, 19, 84, 0.9) 86%, #0d1354);
    }

}

.bottom-decoration{
    @include position;
    .banner-bottom-decoration {
        // @include position(absolute, 9, $bottom:-150px, $left:0);
        height: 150px;
        width: 100%;
        @include position(relative, -1);
        @include transform(translateY(-100%));

        .decoration-center {
            content: '';
            @include position(absolute, -1, $bottom:-150px, $left:50%);
            @include transform(translateX(-50%));
            // @include absolute-center(x);
            height: 150px;
            width: 790px;
            background-repeat: no-repeat;
            background-position: center center;
        }

        .decoration-left {
            content: '';
            @include position(absolute, -1, $bottom:-151px, $left: -1px, $right: calc(50% + 394px));
            height: 150px;
            background-color: $white-color;
        }

        .decoration-right {
            content: '';
            @include position(absolute, -1, $bottom:-151px, $left: calc(50% + 394px), $right:-1px);
            height: 150px;
            background-color: $white-color;
        }

		@media (max-width: 790px)
		{
            .decoration-center {
                left: 0;
                right: 0;
                @include transform(none);
                width: 100%;
            }
        }
    }
}

.bootstrap-select {

	.btn {
		@include font-p($font-base, $weight-regular, 1.1, $brand-gray-01);
		@include button-theme($brand-gray-01, transparent, transparent);
		padding: 20px 24px;
		border: 1px solid $brand-gray-03 !important;

		&:hover, &:active, &:focus
		{
			outline: 0 !important;
			box-shadow: none !important;

			@include button-theme($brand-gray-01, $white-color, $brand-danger);
		}
	}

	.dropdown-toggle {
		@include position;

		&:before {
			content: url('../icons/dropdown-arrow-icon.svg');
			width: 20px;
			height: 20px;

			@include absolute-center(y) {
				left: auto;
				right: 12px;
			}
		}

		&:after {
			@include opacity(0);
		}

	}

	&.show {
		.dropdown-toggle {
			&:before {
				// @include transform(rotate(180deg) translateY(-50%));
				@include transform(scaleY(-1) translateY(50%));
			}
		}
	}

	.dropdown-menu {
		//@include position(absolute, 9, $top:0, $left:auto, $right:0);
		top: 4px !important;
		left: auto !important;
		right: 0 !important;

		a {
			.text {
				@include font-s-14($weight-light, 1.43, $brand-primary);
			}
		}

		li
		{
			a
			{
				&:hover, &:active, &:focus
				{
					background-color: $brand-light !important;
				}
			}

			&.selected,
			&.active {
				background-color: $brand-light;

				a {

					&.selected, &.active, &:hover, &:active, &:focus
					{
						background-color: $brand-light !important;
					}
				}
			}

			&.disabled {
				a {
					.text {
						@include font-s-14($weight-light, 1.43, $brand-gray-01);
					}
				}
			}
		}
	}

	&.text-center {

		.filter-option-inner-inner {
			text-align: center;
		}
	}

	@include media-breakpoint-down(lg)
	{
		.btn
		{
			padding: 15px 20px;
		}
	}
	// @include media-breakpoint-down(sm) {
	// 	.btn {
	// 		padding: 10px 20px;
	// 	}
	// }
}

.video-modal {
    .modal-content {
        padding: 0;

        .close-btn {
            font-size: 30px;
            color: $white-color;
            @include position(absolute, $right: 0px);
            @include transform(translateY(-100%));
        }
    }
}

.twentytwenty-container
{
	.twentytwenty-overlay
	{
		display: none !important;
	}
}

.thankyou-page {
	.banner-container {
		height: 500px;
		>.container {
			height: 100%;
			@include display-flex(column, center, center);
		}

		.banner-wrapper {
			@include reset-position;

			h1 {
				font-size: 36px;
			}
		}
	}

	footer {
		padding-top: 30px;
	}
}

p {
	@include font-sub($font-sm, $weight-regular, 1.43, $white-color);
	letter-spacing: 1.1px;
	@include transition;
}

a {
	@include font-sub($font-sm, $weight-regular, 1.43, $brand-primary);
	letter-spacing: 1.1px;
	// text-decoration: underline;
}

.twitter-typeahead {
	.tt-menu {
		width: 100%;
		background-color: $white-color;
		border-radius: 0 0 5px 5px;
		overflow: hidden;

		.tt-suggestion {
			padding: 10px 20px;
			@include font-p($font-xs, $weight-regular, 1.1, $brand-primary);
			@include transition;

			&:hover, &:active, &:focus
			{
				background-color: $brand-primary;
				color: $white-color
			}
		}
	}
}

.lightbox {
	.lb-nav {
		a.lb-next, a.lb-prev {
			@include opacity(1);
		}
	}

	@include media-breakpoint-down(md)
	{
		.lb-outerContainer{
			width: 80vw !important;
			height: auto;
		}

		.lb-image {
			width: 100% !important;
			height: auto !important;
		}
	}
}

.base-page {
	padding: 50px 0;
	h3 {
		@include font-p(rem-calc(28px), $weight-regular, 1.43, $brand-primary);
	}

	h2, h3 {
		color: $brand-primary;
	}

	p, li {
		@include font-p($font-base, $weight-regular, 1.43, $brand-primary);
		@include transition;
		letter-spacing: 1px;
	}

	ul {
		margin-left: 30px;

		li {
			margin-bottom: 10px;
		}
	}

	p, ul, h2, h3 {
		margin-bottom: 20px;
	}

	a {
		@include font-p($font-base, $weight-regular, 1.43, $brand-danger);
		@include transition;
		&:hover, &:active, &:focus
		{
			color: $brand-primary
		}
	}
}
