/*----------  Background Defaults  ----------*/

@mixin background-defaults()
{
	-webkit-background-size: cover;
	background-size: cover;

	-webkit-background-position: center center;
	-moz-background-position: center center;
	-ms-background-position: center center;
	-o-background-position: center center;
	background-position: center center;

	-webkit-background-repeat: no-repeat;
	-moz-background-repeat: no-repeat;
	-ms-background-repeat: no-repeat;
	-o-background-repeat: no-repeat;
	background-repeat: no-repeat;
}