@mixin rotate($degree) {
    -webkit-transform: rotate($degree);
    -moz-transform: rotate($degree);
    -o-transform: rotate($degree);
    -ms-transform: rotate($degree);
    transform: rotate($degree);
}

@mixin min-height($height) {
	min-height: $height;
	height:auto !important;
	height: $height;
}
