.property-banner-wrapper{
    padding: 80px 0;

    // h3{
    //     margin-bottom: 14px;
    // }


    .primary-mini-link{
        margin-bottom: 54px;
    }

    .info-wrapper{
        @include display-inline-flex;
        justify-content: left;
        margin-bottom: 80px;

        .info{
            @include font-p-20($weight-light, 1.8, $brand-gray-01);
            margin-right: 24px;
        }

        .img-rate{
            width: 80px;
        }
    }

    .price-wrapper{
        @include display-inline-flex(center, space-between);
        @include flex(2);
        margin-bottom: 60px;

        .price-text{
            @include font-sub($font-md, $weight-regular, 1.4, $brand-primary);
        }

        .price{
            @include font-sub(rem-calc(42px), $weight-regular, 1.33, $brand-primary);
        }

        .btn-wrapper{
            // margin-left: 90px;

            .btn-outlined-danger{
                @include display-inline-flex(center, center);
                padding: 5px 24px;

                span{
                    margin-left: 10px;
                }
            }
        }
    }

    .property-info{

        @include display-inline-flex(center, center);
        @include flex-wrap(wrap);
        margin-bottom: 130px;

        .info-block{
            @include display-flex(column, center, center);

            width: 100%;
            max-width: 138px;
            height: 138px;
            background-color: $white-color;
            border-radius: 5px;
            // margin-right: 20px;
            margin: 0 8px 20px;
            @include flex(1 1 auto);

            span{
                margin-top: 18px;
                @include font-sub($font-lg, $weight-regular, 1.4, $brand-primary);
            }

            &:first-child{ margin-left: 0;}
			&:last-child{ margin-right: 0;}

		}

		@media (max-width: 1520px) {
			.info-block {
				max-width: 100px;
				height: 100px;
			}
		}
    }

    .call-action{
        text-align: center;
        margin-bottom: 97px;

        .text{
            @include font-p-20($weight-medium, 1.42, $brand-primary);
            margin-bottom: 24px;
        }

        .button-wrappers{
            @include display-inline-flex(center, center);

            a, span{
                margin: 0 12px;
            }
        }
    }

    .social-icons-wrapper{
        .social-icons{
            @include display-inline-flex(center, center);
            list-style-type: none;

            li{
                a{
                    @include max-width(24px);
                    margin: 0 12px;

                    svg{
                        width: 24px;
                        path{
                            stroke: $brand-gray-01;
                        }
                    }
				}

				.fav-icon {
					&.active {
						svg {
							fill: $brand-danger;

							path, g{
								stroke: $brand-danger;
								fill: $brand-danger;
							}
						}
					}
				}
            }
        }
	}

	.open-days {
		@include position(absolute, 5, $top: 0, $bottom: 0);
		background-color: $white-color;
		@include box-shadow(0 16px 30px 0 rgba(213, 215, 233, 1));
		width: 33%;
		@include flex(1);
		@include border-radius(0 0 6px 6px);
		// @include max-width(296px);
		.open-days-inner {
			@include display-flex(column, flex-start, center);
			height: 100%;
		}

		.head {
			width: 100%;
			@include border-radius(6px 6px 0 0);
			background-color: $brand-danger;
			padding: 16px 0;
			text-align: center;
			p {
				@include font-sub(12px, $weight-regular, 1.2, $white-color);
			}
		}
		.date {
			@include display-flex(column, center, center);
			width: 100%;
			@include border-radius(0 0 6px 6px);
			@include flex(1 1 auto);
			p {
				text-align: center;
				@include font-p(20px, $weight-regular, 1.3, $brand-primary);
				span {
					font-weight: $weight-medium;
					display: block;
					margin-bottom: 12px;
				}
			}
		}

		@include media-breakpoint-down(sm)
		{
			@include reset-position;
			width: 100%;
			margin-top: 20px;
			.date {
				padding: 24px 0;
			}
		}
	}

    .p-img-wrapper{
        .primary-image{
            margin-bottom: 20px;
            border-radius: 5px;
			@include position;
			height: auto;

			>a {
				width: 100%;
			}

			img {
				@include border-radius(5px);
			}

            .play {
				@include absolute-center;
				width: auto;
            }
        }

		.sub-images-holder {
			@include position;
		}
        .sub-images-wrapper{
            @include display-inline-flex;

            .sub-image{
                border-radius: 5px;
                overflow: hidden;
				@include flex(1);
				max-height: 170px;
				// @include max-width(296px);
				max-width: 33%;

				margin: 0 10px;
				.overlay-text{
					@include absolute-center();
					@include font-s-36($weight-light, 1.39, $white-color);
					display: none;
				}

                &:first-child{ margin-left: 0;}
				&:last-of-type{
					margin-right: 0;
					@include position;
					cursor: pointer;

					&:before {
						content: '';
						@include position(absolute, 2, 0, 0);
						height: 100%;
						width: 100%;
						object-fit: contain;
						background-color: rgba($color: #000000, $alpha: 0.4);
						pointer-events: none;
					}

					.overlay-text {
						display: block;
					}
				}
            }
		}

		img {
			width: 100%;
		}
    }

    .overlay{
        @include position;
        cursor: pointer;

        &:before {
            content: '';
            @include position(absolute, 2, 0, 0);
            height: 100%;
            width: 100%;
            object-fit: contain;
            background-color: rgba($color: #000000, $alpha: 0.4);
            pointer-events: none;
        }
    }

    @include media-breakpoint-down(lg) {
        padding: 40px 0 20px;
        .primary-mini-link, .info-wrapper {
            margin-bottom: 20px;
            text-align: left;
            @include justify-content(flex-start);

            .button-wrappers, .social-icons {
                @include justify-content(flex-start);

                a:first-child {
                    margin-left: 0;
                }
            }
		}

		.call-action {
			margin-top: 30px;
		}

		.property-info .info-block {
			max-width: 138px;
			height: 138px;
		}

		.property-info,  .call-action, .social-icons-wrapper {
            margin-bottom: 20px;
			text-align: center;
			@include justify-content(center);
			.button-wrappers, .social-icons {
                @include justify-content(center);

                a:first-child {
                    margin-left: 0;
                }
            }
		}
    }

    @include media-breakpoint-down(xs) {
        .price-wrapper{
            display: block;
            .btn-wrapper{
                margin: 15px 0;

                .btn-outlined-danger{
                    display: inline-block;
                }
            }

        }

        .property-info{
            .info-block{
                max-width: 40%;
				&:first-child{ margin-left: 8px;}
				&:last-child{ margin-right: 8px;}
            }
        }

        .call-action .button-wrappers {
            @include display-flex(column, center, center);
            a, span {
                margin: 5px 0;
            }
        }
    }
}

.page{

    h4{
        @include font-p-32($weight-regular, 1.06, $brand-primary);
    }

    .mini-primary-text{
        @include max-width(454px, false);
    }

    @include media-breakpoint-down(xs) {
        .mini-primary-text {
            @include max-width(100%, false);
        }
    }

}

.property-details-wrapper{
    h4{
        margin-bottom: 40px;
        font-weight: $weight-medium;
    }
    .detail-row{
        @include display-inline-flex(center, flex-start);
		@include flex-wrap(wrap);
        // align-items: stretch;

        margin-bottom: 24px;

		&:last-child {
			margin-bottom: 0;
		}

        .block{
			border-bottom: 1px solid $brand-gray-01;
			@include display-flex(column, flex-start, space-between);
            width: 130px;
			@include flex(1 1 auto);
			max-width: 33%;

            .heading{
                @include font-sub($font-xs, $weight-regular, 1.2, $brand-primary);
                letter-spacing: 1.2px;
                margin-bottom: 14px;
                text-transform: uppercase;
            }
            .text{
                @include font-p-20($weight-regular, 1.4, $brand-primary);
                margin-bottom: 24px;

                &.active{
                    color: $brand-danger;
                }

            }
		}

        &:last-child{
			.block {
				border: 0;
			}
        }
    }
}

.property-plan-wrapper{
    height: 628px;
    @include position;

    .img-wrapper{
        // margin-right: 200px;
        // margin-left: 30px;
        // margin-bottom: 70px;
    }

    .documentation-wrapper {
        height: 100%;
        width: 100%;
        @include display-flex(column, center, center);


        .gray-mini-text{
            @include font-sub($font-xs, $weight-regular, 1.2, $brand-gray-01);
            text-transform: uppercase;
            letter-spacing: 1.2px;

            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }

        h4 {
            @include font-s-46($weight-medium, 1.22, $brand-primary);
            margin-bottom: 40px;
        }

        .btn-outline-danger{
            svg{
                line{
                    stroke: $brand-danger;
                }
            }

            &:hover, &:active, &:focus{
                svg {
                    line {
                        stroke: $white-color;
                    }
                }
            }
		}

        @include media-breakpoint-down(md) {
			margin-bottom: 0px;
			padding: 40px 0;
			h4 {
				margin-bottom: 20px;
			}
        }
    }

    .btn-large-danger-wrapper{
        @include absolute-center(x){
            top: auto;
            bottom: 50px;
        }
    }
}

.property-info-list{
    h4{
        margin-bottom: 40px;
        font-weight: $weight-medium;
    }
    ul{
        list-style-type: none;
        li{
            background: url('../icons/checkmark-icon.svg') no-repeat left center;
            @include font-p-16($weight-regular, 2.63, $brand-primary);
            // @include background-defaults;
            padding-left: 40px;
            background-size: 16px 16px;

            img{
                max-height: 24px;
            }
        }
    }
}

.location-bar{
    @include display-inline-flex(center, space-between);

    @include media-breakpoint-down(lg) {
        @include flex-direction(column);
	}

    .lblock{
        text-align: center;

        @include media-breakpoint-down(md) {
			margin: 20px auto;
			text-align: center;

			&:first-child{
				.gray-mini-text{
					margin-bottom: 16px;
				}
			}
        }
		@include media-breakpoint-down(xs) {
			h3 {
				font-size: rem-calc(32px);
			}
		}

        svg{
            @include max-width(24px);
            margin-bottom: 24px;
        }

        .gray-mini-text{
            margin-bottom: 16px;
            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }
        .primary-text{
            @include font-s(rem-calc(24px), $weight-medium, 1.17, $brand-primary);
        }
    }

	>.lblock {
		text-align: left;
		max-width: 400px;
		.gray-mini-text{
			margin-bottom: 0;
		}

        @include media-breakpoint-down(lg) {
			margin: 30px auto;
			text-align: center;

			&:first-child{
				.gray-mini-text{
					margin-bottom: 16px;
				}
			}
        }
	}

	.lblock-wrapper {
		@include display-inline-flex(flex-start, flex-end);

		.lblock {
			flex: 1 1 auto;
			width: 300px;
		}

		@include media-breakpoint-down(md) {
			@include flex-direction(column);
		}
	}

}

.location-bottom-info{
    @include position;
    height: 534px;
    overflow: hidden;

    .pimg-wrapper{
        max-width: 50%;
        @include position(absolute, 2, 0, 0);
        overflow: hidden;
        border-top-right-radius: 80%;


        @include media-breakpoint-down(lg) {
            @include max-width(100%);
            @include reset-position;
            border-radius: 0;
            padding: 0;
        }
    }

    .location-info{
        padding-left: 178px;


        .heading{
            @include font-s-24($weight-medium, 1.42, $brand-primary);
            margin-bottom: 40px;
        }

        .p-text{
            @include font-p-16($weight-regular, 1.5, $brand-primary);
        }
    }
}

.property-mini-slider{
    padding: 120px 0 60px;
    h3 {
        font-weight: $weight-medium;
	}

    .property-mini-slider-wrapper {
        .property-blocks-wrapper {
            @include display-inline-flex(flex-start, center);
            @include flex-wrap(wrap);
        }
        .property-block{
            @include max-width(440px);
            height: 440px;
            margin: 0 9px 60px;
            overflow: hidden;
            @include position;

            .property-slider-wrapper {
                height: 320px;
            }

            .slick-dots{
                bottom: 0px;
            }

            .property-details-wrapper {
                background-color: $white-color;
                padding: 40px;
                width: 100%;
                .property-details {
                    @include display-inline-flex(flex-start, space-between);
                    .property-price {
                        @include opacity(0);
                        @include transition;
                    }
                }
                .property-info{
                    .location{
                        // margin: 0;
                    }
                }
            }

            .slider-overlay{
                @include position(absolute, 4, $left: -1px, $right: -1px, $bottom: -90px);
                @include transition;
            }

            .property-sub-info {
                // @include opacity(0);
                background-color: $white-color;
                padding: 24px 40px;
                @include transition;
                @include display-inline-flex(center, space-between);

                .sub-info{
                    .info{
                        margin-right: 15px;
                        margin-bottom: 0;
                    }
                }
            }

            &:hover, &:active, &:focus{
                .slick-dots{
                    bottom: 50px;
                }

                .slider-overlay{
                    // @include position(absolute, 5, $bottom:0, $left:0);
                    bottom: 0;
                }

                .property-details-wrapper {
                    .property-details{
                        .property-price {
                            @include opacity(1);
                        }
                    }
                }

			}

			@media (max-width: 1878px) {
				&:last-child {
					display: none;
				}
			}
			@media (max-width: 1420px) {
				&:last-child {
					display: block;
				}
			}

            @include media-breakpoint-down(md) {
				.property-slider-wrapper {
                    height: 250px;
				}

                height: auto;
                .slick-dots{
                    bottom: 50px;
                }

                .slider-overlay{
                    @include position(static, 2);
					.property-details-wrapper {
						@include position(relative, 5);
						/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100,ffffff+100&0+0,1+40,1+100 */
						background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
						background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
					}
                }

                .property-details-wrapper {
                    .property-details{
                        .property-price {
                            @include opacity(1);
                        }
                    }
                }
            }

            @include media-breakpoint-down(xs) {
                .property-slider-wrapper {
                    // height: auto;
                }
                .slider-overlay {
					.property-details-wrapper {
						background: $white-color;
						padding: 20px;
					}
                    .property-details {
                        @include flex-direction(column);

                        .property-info {
							// margin-bottom: 10px;
							.name {
								font-size: 20px;
							}
                        }
                    }
                    .property-sub-info {
						padding: 20px;
						&:before {
							width: calc(100% - 20px);
						}
                        // @include flex-direction(column);
                        // .inner-wrapper {
                        //     @include flex-wrap(wrap);
                        //     margin-bottom: 10px;
                        // }
                        // .sub-info {
                        //     max-width: 120px;
                        //     margin: 5px;
                        //     @include flex(1 1 auto);
                        //     width: 100%;
                        //     @include flex-wrap(nowrap);
                        //     @include justify-content(flex-start);

                        //     .info {
                        //         margin: 0;
                        //         margin-left: 10px;
                        //     }
                        // }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 60px 0 30px;
    }
}

.property-banner{
    @include position;
    // margin-bottom: 150px;

    .banner-bottom-decoration {
        @include position(absolute, -1, $bottom:0, $left:0);
        height: 150px;
        width: 100%;

        .decoration-before {
            height: 150px;
            background-color: $white-color;
            @include position(absolute, -1, $bottom:0px, $left:0);
            // background-color: red;
            width: 120px;
        }

        .decoration-center {
            content: '';
            @include position(absolute, -1, $bottom:0px, $left:120px);
            height: 150px;
            width: 790px;
            background-repeat: no-repeat;
            background-color: $brand-gray-02;
        }


        .decoration-after {
            content: '';
            @include position(absolute, -1, $bottom:0px, $left:910px, $right:0);
            height: 150px;
            background-color: $white-color;
            // background-color: green;
        }
	}
	@media (max-width: 1750px)
	{
		.banner-bottom-decoration {
			.decoration-before {
				display: none;
				width: 0;
			}

			.decoration-center {
				left: 0;
			}

			.decoration-after {
				left: 780px;
			}
		}
	}

	@media (max-width: 1500px)
	{
		.banner-bottom-decoration {
			.decoration-center {
				left: -100px;
			}

			.decoration-after {
				left: 680px;
			}
		}
	}

    @include media-breakpoint-down(lg) {
        .banner-bottom-decoration {
            display: none;
        }
    }
}

.property-single-main {
	.property-banner-wrapper {
		@include media-breakpoint-down(lg)
		{
			h3 {
				margin-top: 25px;
			}
		}
	}
    .property-plan-wrapper{
        height: 628px;
        @include position;

		.row, .cs-col {
			height: 100%;
		}

        .img-wrapper{
			text-align: center;
			height: 100%;
			// margin-right: 200px;
            // margin-left: 30px;
			// margin-bottom: 70px;

			img {
				max-height: 100%;
			}
        }

        .btn-large-danger-wrapper{
            @include absolute-center(x){
                top: auto;
                bottom: 0;
            }
            @include transform(translate(-50%, 50%))
        }

        @include media-breakpoint-down(lg) {
            .btn-large-danger-wrapper{
				margin: 20px 10px;
                @include reset-position;
                text-align: center;
            }
			height: auto;

			.cs-col {
				height: auto;
			}
		}

        @include media-breakpoint-down(md) {
			.img-wrapper{
				width: 60%;
				margin: auto;
			}
		}
        @include media-breakpoint-down(sm) {
			.img-wrapper{
				width: 100%;
			}
		}
    }

    .documentation-wrapper {
        @include border-radius(0 0 0 50%);
        @include media-breakpoint-down(md) {
			@include border-radius(0);
			text-align: center;
        }
	}

	.location-bottom-info {
		@include align-items(center);
	}

	.locality-wrapper {
		@include media-breakpoint-down(lg)
		{
			padding-top: 0;
		}
	}

	.virtual-viewer {
		// padding-top: 60px;
		h4 {
			font-weight: $weight-medium;
			margin-bottom: 30px;
		}
		@include media-breakpoint-down(lg)
		{
			// padding-top: 30px;
		}
		@include media-breakpoint-down(sm)
		{
			// padding-top: 15px;

			.embed-responsive {
				height: 500px;
			}
		}
	}

	.ptb-120 {
		@include media-breakpoint-down(lg)
		{
			padding: 60px 0;
		}
		@include media-breakpoint-down(sm)
		{
			padding: 20px 0;
		}
	}
}
