.page {
    // header {
    //     @include position(absolute, 999, 55px);
    // }

    .page-banner-container {
        height: 100%;
        height: 878px;
        @include background-defaults;
        @include position;

        &:before {
            content: '';
            @include position(absolute, -1, 0, 0);
            height: 100%;
            width: 100%;
            background-color: rgba(13, 19, 84, 0.8);
        }

        .banner-outer-wrapper {
            @include absolute-center;
            width: 80%;
        }

        .banner-wrapper {
            @include display-inline-flex(center, space-between); //changed for service page
            margin-top: 140px;
        }

        h1 {
            @include font-s-64($weight-light, 1.41, $white-color);
            margin-bottom: 10px;
        }

        h2 {
            @include font-p-30($weight-medium, 1.13, $white-color);
            margin-bottom: 24px;
        }

        .sub-title {
            @include font-p-32($weight-light, 1.06, $white-color);
            margin-bottom: 70px;
        }

        .info {
            @include font-s-14($weight-regular, 1.43, $white-color);
            text-transform: uppercase;

            svg {
                margin-right: 10px;
            }
        }

        .image-wrapper {
            img{
                @include max-width(770px);
            }
        }


        .banner-bottom-text {
            text-align: center;
            margin-top: 58px;
        }
    }

    .inner-content-wrapper {
        // @include display-inline-flex;

        .item-center{
            align-items: center;
        }

        .yt-wrapper {
            @include max-width(770px);
            @include position;

            .play{
                @include absolute-center;
                text-align: center;
            }
        }

        .text-wrapper {
            @include max-width(612px, false);

            &.right-block {
                margin-left: auto;
            }

            &.left-block {
                margin-right: auto;
            }

            h2 {
                @include font-s-32($weight-bold, 1.2, $brand-primary);
                text-transform: uppercase;
                margin-bottom: 55px;
            }

            p {
                &.sub-title {
                    @include font-s-20($weight-regular, 1.4, $brand-primary);
                    margin-bottom: 24px;
                }

                &.content {
                    @include font-p-16($weight-regular, 1.5, $brand-gray-01);
                }
            }

            @include media-breakpoint-down(md) {
				h2 {
					margin-bottom: 15px;
                    font-size: rem-calc(22px);
				}
				p {
					&.sub-title {
						margin-bottom: 15px;
						font-size: $font-md;
					}
				}
			}
            @include media-breakpoint-down(sm) {
                h2 {
                    margin-bottom: 15px;
                    font-size: rem-calc(20px);
                }

                p {
                    &.sub-title {
                        margin-bottom: 15px;
                        font-size: $font-base;
                    }
                }
            }
		}

		@include media-breakpoint-down(xs)
		{
			.yt-wrapper {
				margin-bottom: 20px;
			}
		}
    }

    .mid-content {
        height: 600px;
        text-align: center;
        @include position;
        @include display-flex(center, center);
        @include background-defaults;

        &:before {
            content: '';
            @include position(absolute, -1, 0, 0);
            height: 100%;
            width: 100%;
            object-fit: contain;

            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db9e8+0,0d1354+80&0+0,1+80 */
            background: -moz-radial-gradient(center, ellipse cover, rgba(125, 185, 232, 0) 0%, rgba(13, 19, 84, 1) 80%);
            /* FF3.6-15 */
            background: -webkit-radial-gradient(center, ellipse cover, rgba(125, 185, 232, 0) 0%, rgba(13, 19, 84, 1) 80%);
            /* Chrome10-25,Safari5.1-6 */
            background: radial-gradient(ellipse at center, rgba(125, 185, 232, 0) 0%, rgba(13, 19, 84, 1) 80%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007db9e8', endColorstr='#0d1354', GradientType=1);
            /* IE6-9 fallback on horizontal gradient */
        }

        .mid-content-wrapper {
            // @include absolute-center;
            @include max-width(928px);
        }

        h2 {
            @include font-s-32($weight-bold, 1.2, $white-color);
            margin-bottom: 36px;
        }

        p.text {
            @include font-s-20($weight-regular, 1.2, $brand-gray-03);
        }

        @include media-breakpoint-down(sm) {
            height: auto;
            padding: 60px 0;
            .mid-content-wrapper {
                @include reset-position;
            }
            h2 {
                font-size: rem-calc(24px);
                margin-bottom: 20px;
            }
            p.text {
                font-size: $font-base;
            }
        }
    }


    .bottom-text-container {
        height: 800px;
        @include position;
        @include display-flex(center, center);
        @include background-defaults;

        &:before {
            // content: '';
            @include position(absolute, -1, 0, 0);

            height: 100%;
            width: 100%;
            object-fit: contain;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+65,ffffff+65&0+0,1+65 */
            background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 85%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 85%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 85%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
            /* IE6-9 */
        }


        .bottom-text-wrapper {
            .text-wrapper {
                @include max-width(612px);
                margin-right: 0;
            }
        }

        h2 {
            @include font-s-32($weight-bold, 1.2, $brand-primary);
            text-transform: uppercase;
            margin-bottom: 32px;
        }

        p {
            &.sub-title {
                @include font-s-20($weight-regular, 1.4, $brand-primary);
                margin-bottom: 24px;
            }

            &.content {
                @include font-p-16($weight-regular, 1.5, $brand-gray-01);
                margin-bottom: 24px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        @include media-breakpoint-down(md) {
            height: auto;
            padding: 60px 0;

            &:before {
                background: rgba($white-color, 0.8);
            }
            .bottom-text-wrapper {
                .text-wrapper {
                    @include max-width(100%);
                }
            }
        }
        @include media-breakpoint-down(sm) {
            h2 {
                font-size: rem-calc(24px);
                margin-bottom: 24px;
            }

            p {
                &.sub-title {
                    font-size: $font-md;
                }
            }
        }
    }

    .agency-brand-wrapper {
        @include display-inline-flex(center, space-around);
        padding: 60px 0;

        .brand-block {

            h2 {
                @include font-s-46($weight-medium, 1.2, $brand-primary);
            }

            p {
                &.sub-title {
                    @include font-s-20($weight-regular, 1.2, $brand-gray-01);
                    margin-bottom: 24px;
                }
            }

            &.brand-image-block {
                padding: 27px;
                background-color: #19447a;
                @include border-radius(999px);
                img {
                    @include max-width(240px);
                }
            }
        }

        @include media-breakpoint-down(sm) {
            @include flex-direction(column);
            .brand-block {
                text-align: center;
                p {
                    &.sub-title {
                        font-size: $font-sm;
                        margin-bottom: 5px;
                    }
                }

                &.brand-image-block {
                    @include order(-1);
                    margin-bottom: 20px;
                    padding: 12px;
                    img {
                        @include max-width(140px);
                    }
                }
            }
        }
    }

    .contact-form-container {
        .contact-form {
            @include max-width(928px);
        }
    }

    // .statstics-wrapper{
    //     padding: 50px 0 120px;
    // }

}

/*   Property Listing Page */

.property-listing-container {
    padding-bottom: 60px;
	@include position;

	.btn-filter {
		display: none;

	}

	@include media-breakpoint-down(md) {
		.btn-filter {
			display: inline-block;
			@include position(sticky, 99, $bottom: 10px, $left: 50%);
			@include transform(translateX(-50%));
		}
	}
}

.property-filters-outer {
    @include position(relative, 9);
}

.property-filter-wrapper {
    @include display-inline-flex(center, center);
    @include flex-wrap(wrap);
    @include position(relative, 5);

    .filter-group {
		margin: 20px 10px;

		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			text-indent: 1px;
			text-overflow: '';
		}
		select::-ms-expand {
			display: none;
		}

		&.reset-btn-container {
			margin: 20px;
		}

    }

    @include media-breakpoint-down(lg) {
        @include justify-content(flex-start);
	}

	@include media-breakpoint-down(xs)
	{
		padding: 10px 0 0 0;
		.filter-group {
			margin: 5px 10px;

			&.reset-btn-container {
				display: inline-block;
				width: auto;
				margin-left: auto;
			}
		}

	}
}

.property-listing-wrapper {
    padding: 20px 0;
}


//slider css
.slick-dotted {
    @include position;

    .slick-arrow {
        @include transition;
        @include opacity(0);
    }

    .slick-prev-arrow {
        @include absolute-center(y) {
            left: 30px;
        }
    }

    .slick-next-arrow {
        @include absolute-center(y) {
            right: 30px;
            left: auto;
        }
    }

    .slick-prev-arrow,
    .slick-next-arrow {
        width: 48px;
        height: 48px;
        text-align: center;
		background-color: $white-color;
		@include box-shadow(0 0 20px 5px rgba($black,0.2));
        cursor: pointer;

        @include transition;
        @include display-flex(column, center, center);
        @include border-radius(50%);

        &:hover, &:active, &:focus {
            background-color: $brand-danger;
            color: $white-color;
            -webkit-box-shadow: 0 10px 6px 0 rgba(0, 0, 0, 0.04);
            box-shadow: 0 10px 6px 0 rgba(0, 0, 0, 0.04);
        }
    }

    .slick-dots {
        @include reset-list;
        @include display-inline-flex;
        @include transition;
        @include opacity(0);

        @include absolute-center(x) {
            bottom: 90px;
            top: auto;
        }

        li {
            .slick-dots-circle {
                height: 12px;
                width: 12px;
                background-color: $white-color;
                margin: 5px;

                @include transition;
                @include border-radius(50%);
            }

            &.slick-active {
                .slick-dots-circle {
                    width: 24px;
                    @include border-radius(10px);
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .slick-arrow {
            @include opacity(1);
        }
        .slick-dots {
            @include opacity(1);
        }
    }
}

.property-block {
    margin-bottom: 20px;
    border-radius: 6px;
    @include transition;

    &:hover {

        .slick-arrow {
            @include opacity(1);

            &.slick-prev-arrow {
                left: 40px;
            }

            &.slick-next-arrow {
                right: 40px;
            }
        }

        .slick-dots {
            @include opacity(1);
            bottom: 100px;
        }
    }
}

.property-info {
    svg {
        margin-top: -5px;
    }
    .name {
        @include font-s-24($weight-medium, 1.2, $brand-primary);
        @include pointer-events(all);
        margin-bottom: 6px;
        margin-left: 3px;
    }

    .location {
        @include font-s-12($weight-regular, 1.33, $brand-gray-01);
        letter-spacing: 1.2;
        text-transform: uppercase;
        margin-left: 20px;
    }
}

.property-price {
	.offer-label {
		color: $brand-primary;
	}
    .price {
        @include font-s-24($weight-regular, 1.2, $brand-primary);
	}

	@include media-breakpoint-down(sm)
	{
		.offer-label {
			font-size: 14px;
			font-weight: normal;
			line-height: 1.43;
			letter-spacing: 1.1px;
			display: block;
			margin-left: 20px;
		}
	}

	@include media-breakpoint-down(xs)
	{
		.offer-label {
			display: inline-block;
		}
	}
}

.property-slider-wrapper {
	@include position(relative, 3);
	height: 440px;
	.property-images {
		height: 100%;
		.property-image-slide-inner {
			@include background-defaults;
			height: 100%;

			img {
				display: none;
			}
		}

		.slick-list, .slick-track, .slick-slide {
			height: 100%;
		}
	}
    .slider-overlay {
        @include position(absolute, 5, $top:0, $left:0);
        width: 100%;
        height: 100%;
        pointer-events: none;
        @include transition;


        .fav-icon {
            @include max-width(30px);
            @include position(absolute, 9, $top:40px, $right:40px);
            @include pointer-events(all);

            .fav-link {
                &.active{
                    svg{
                        fill: $brand-danger;

                        path, g{
                            stroke: $brand-danger;
                            fill: $brand-danger;
                        }
                    }
                }
            }
        }

        .property-details-wrapper {
            @include position(absolute, 9, $bottom:0px, $left:0px);
            width: 100%;
            padding: 40px;

            &:before {
                content: '';
                @include position(absolute, -1, 0, 0);
                height: 100%;
                width: 100%;
                object-fit: contain;

                background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75) 31%, rgba(255, 255, 255, 0.95) 70%, #ffffff);
            }
        }

        .property-details {
            @include display-inline-flex(flex-start, space-between);
        }
    }
}

.property-sub-info {
    padding: 24px 40px;
    @include display-inline-flex(flex-start, flex-start);
    @include position;

    &:before {
        content: '';
        height: 1px;
        width: calc(100% - 80px);
        background-color: $brand-gray-03;
        @include absolute-center(x);
    }

    .inner-wrapper {
        @include display-inline-flex;
        @include flex(1);
        @include flex-wrap(wrap);
    }

    .sub-info {
        @include display-inline-flex(center, flex-start);
        // @include flex(1 1 auto);
        margin-bottom: 10px;
        .info {
            @include font-s-14($weight-regular, 1.43, $brand-primary);
            margin-left: 8px;
            margin-right: 20px;
            white-space: nowrap;
        }
    }

    .property-rate {
		@include max-width(82px, false);
		img {
			width: 100%;
		}
	}

	@include media-breakpoint-down(xs)
	{
		.sub-info {
			margin-bottom: 0;
		}
	}
}

.open-days-mobile {
	display: none;
	padding: 24px;
	p {
		@include font-s-14($weight-regular, 1.43, $brand-primary);
		text-transform: uppercase;
	}

	.date-block {
		width: 64px;
		height: 64px;
		text-align: center;
		@include display-flex(column, center, center);
		background-color: $brand-danger;
		@include font-s-14($weight-regular, 1.2, $white-color);
		@include border-radius(6px);
		span {
			font-size: 20px;
			display: block;
		}
	}

	.inner-wrapper {
		@include display-inline-flex(center, space-between);
	}

	@include media-breakpoint-down(xs)
	{
		display: block;
	}
}
