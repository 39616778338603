/*----------  Secondary Fonts  ----------*/

@mixin font-s-12($font-weight, $line-height, $color) {
    @include font-s($font-xs, $font-weight, $line-height, $color);
}

@mixin font-s-14($font-weight, $line-height, $color) {
    @include font-s($font-sm, $font-weight, $line-height, $color);
}

@mixin font-s-16($font-weight, $line-height, $color) {
    @include font-s($font-base, $font-weight, $line-height, $color);
}

@mixin font-s-18($font-weight, $line-height, $color) {
    @include font-s($font-md, $font-weight, $line-height, $color);
}

@mixin font-s-20($font-weight, $line-height, $color) {
    @include font-s(20px, $font-weight, $line-height, $color);
}

@mixin font-s-24($font-weight, $line-height, $color) {
    @include font-s(24px, $font-weight, $line-height, $color);
}

@mixin font-s-32($font-weight, $line-height, $color) {
    @include font-s(32px, $font-weight, $line-height, $color);
}

@mixin font-s-36($font-weight, $line-height, $color) {
    @include font-s(36px, $font-weight, $line-height, $color);
}

@mixin font-s-42($font-weight, $line-height, $color) {
    @include font-s(42px, $font-weight, $line-height, $color);
}

@mixin font-s-46($font-weight, $line-height, $color) {
    @include font-s(46px, $font-weight, $line-height, $color);

    @include media-breakpoint-down(md){
        @include font-s-32($font-weight, $line-height, $color);
    }

    @include media-breakpoint-down(sm){
        @include font-s-24($font-weight, $line-height, $color);
    }
}

@mixin font-s-52($font-weight, $line-height, $color) {
    @include font-s(52px, $font-weight, $line-height, $color);
}

@mixin font-s-64($font-weight, $line-height, $color) {
    @include font-s(64px, $font-weight, $line-height, $color);

    @include media-breakpoint-down(md){
        @include font-s-46($font-weight, $line-height, $color);
    }

    @include media-breakpoint-down(sm){
        @include font-s-32($font-weight, $line-height, $color);
    }
}



/*----------  Typography  ----------*/

@mixin font-p-16($font-weight, $line-height, $color) {
    @include font-p($font-base, $font-weight, $line-height, $color);
}

@mixin font-p-20($font-weight, $line-height, $color) {
    @include font-p(20px, $font-weight, $line-height, $color);

    @include media-breakpoint-down(md){
        @include font-p-16($font-weight, $line-height, $color);
    }
}

@mixin font-p-24($font-weight, $line-height, $color) {
    @include font-p(24px, $font-weight, $line-height, $color);
}

@mixin font-p-30($font-weight, $line-height, $color) {
    @include font-p(30px, $font-weight, $line-height, $color);

    @include media-breakpoint-down(md){
        @include font-p-24($font-weight, $line-height, $color);
    }

    @include media-breakpoint-down(sm){
        @include font-p-16($font-weight, $line-height, $color);
    }
}

@mixin font-p-32($font-weight, $line-height, $color) {
    @include font-p(32px, $font-weight, $line-height, $color);
}

@mixin font-p-64($font-weight, $line-height, $color) {
    @include font-p(64px, $font-weight, $line-height, $color);

    @include media-breakpoint-down(md){
        font-size: 46px;
    }

    @include media-breakpoint-down(sm){
        font-size: 32px;
    }
}
