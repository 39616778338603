/*----------  Header  ----------*/

header
{
	@include position(relative, 999, $left:0);
	width: 100%;
}

.pre-header
{
	background-color: rgba($brand-primary, 0.8);
	text-align: center;
	height: 70px;
	@include position(absolute, 999, 0, 0);
	width: 100%;

	.close-btn {
		display: none;
	}

	p{
		display: inline-block;
		@include font-p($font-md, $weight-light, 70px, $white-color);
		margin-right: 140px;
	}

	@include media-breakpoint-down(lg){
		p {
			margin-right: 30px;
		}
	}

	@include media-breakpoint-down(md){
		background-color: $brand-primary;
		.pre-header-container {
			@include display-flex(column, center, center);
		}

		.pre-header-wrapper {
			padding: 30px 0;
			@include position;
		}

		.close-btn {
			display: block;
			@include absolute-center(x);
			@include transform(translate(-50%, -50%));
			background-color: $brand-primary;
			@include border-radius(999px);
			height: 50px;
			width: 50px;
			color: $white-color;
			font-size: 30px;
			@include display-flex(column, center, center);
		}

		p {
			margin-right: 0;
			line-height: 2;
			margin-bottom: 30px;
		}
		// display: none;
		height: auto;
		@include position(fixed, 99, $bottom: 0, $top: auto);
	}
}


.cookiebar
{
	background-color: $white-color;
	text-align: center;
	@include display-inline-flex(center, center);
	padding: 13px 0;
	width: 100%;

	p {
		display: inline-block;
		@include font-p($font-sm, $weight-regular, 1.5, $brand-primary);
		margin-right: 20px;
		a {
			color: $brand-danger;
			&:hover, &:active, &:focus
			{
				color: $brand-primary;
			}
		}
	}

	.btn {
		color: $brand-primary;

		&:hover, &:active, &:focus
		{
			color: $white-color;
		}
	}

	@include media-breakpoint-down(xs)
	{
		p {
			font-size: 11px;
			margin-right: 0;
			margin-bottom: 5px;
			a {
				font-size: 11px;
			}
		}

		.btn {
			color: $brand-danger;
			border: 0;
			padding: 0;
			border-bottom: 2px solid $brand-danger;
			@include border-radius(0);
			font-size: 11px;

			&:hover, &:active, &:focus
			{
				background-color: transparent;
				color: $brand-danger;
			}
		}
	}
}
