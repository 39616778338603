/*----------  Containers  ----------*/

.container
{
	$gutter: $container-default-gutter / 2;

	padding-left: #{$gutter}px;
	padding-right: #{$gutter}px;

	@include max-width(100%);
}

@each $breakpoint, $width in $container-default-widths
{
	.container
	{
		@include media-breakpoint-up(#{$breakpoint})
		{
			max-width: $width;
		}
	}
}