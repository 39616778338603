.banner-container{

    .banner-wrapper{
        @include media-breakpoint-down(md){
            height: 100px;
			@include absolute-center;

			// @include transform(none);
			// @include position(absolute, 5, $top: auto, $bottom: 40px, $left: 0, $right: 0);
        }
    }

    .btn-outline-white{
        padding: 14px 24px;
    }

    .circle-wrapper{
        // @include max-width(180px);
        width: 180px;
        margin: 0 auto;

        .percentage{
            @include font-s-52($weight-light, 1.08, $white-color);
        }

        canvas{
            width: 100%;
        }
	}

	@include media-breakpoint-down(xs) {
		@include display-flex(column, center, center);
		height: 700px;
		>.container-xl {
			// height: 100%;
			// @include display-inline-flex(center, center);
			margin: 0;
		}
		.banner-wrapper{
			height: auto;
			@include reset-position;
		}
	}
}

.banner-footer {
    width: 80%;
    @include absolute-center(x) {
        top: auto;
        bottom: 60px;
    }
    @include display-inline-flex(center, center);
	justify-content: space-around;

    .bf-block{
        min-width: 320px;

        @include media-breakpoint-down(md){
            min-width: 100%;
            text-align: center;
            margin: 12px 0;
        }
	}

    @include media-breakpoint-down(md){
        @include position(absolute, 2, $bottom: 0);
        @include flex-direction(column);
	}

	@include media-breakpoint-down(xs) {
		width: 100%;
		@include reset-position;
	}

}

.primary-text-wrapper{

    .top-bar{
        @include display-inline-flex(center, space-between);
        margin-bottom: 54px;

        .social-bar{

            .slink{
                margin-left: 24px;
                font-size: 24px;
                color: $white-color;
                svg{
                    @include max-width(24px);
                    path {
                        @include transition;
                    }
                }

                &:hover, &:active, &:focus
                {
                    color: $brand-danger;

                    svg {
                        path {
                            stroke: $brand-danger;
                        }
                    }
                }
            }


        }
    }

    .white-mini-primary-text-16{
        margin-bottom: 40px;
    }

    .btn-wrapper{
        @include media-breakpoint-down(md) {
            text-align: center;
            margin-bottom: 40px;
        }

        a{
            margin: 10px auto;

        }

    }

    .btn-outline-danger{
        padding: 10px 14px;
    }

    .property-image-wrapper{
        margin-left: 178px;

        @include media-breakpoint-down(lg) {
            margin-left: 0;
        }

        img {
            border-radius: 5px;
        }
    }

    .call-action{
        margin-top: 80px;
        width: 100%;
        text-align: center;

        .heading{
            @include font-p-24($weight-regular, 1.42, $white-color);
        }

        .button-wrappers{
            a{
                margin: 0 24px;
            }

            @include media-breakpoint-down(sm) {
                a, span{
                    @include max-width(100%);
                }
            }
        }

    }

    @include media-breakpoint-down(md) {
        .call-action {
            margin-top: 40px;
            padding-bottom: 40px;
        }

        .banner-bottom-decoration {
            display: none;
        }
    }
}

.video-slider-wrapper{
    @include max-width(1244px);

    @include media-breakpoint-down(md) {
        // height: 400px;
        @include max-width(100%);
    }


    .showcase-slider-block{
        @include position;

        .play {
            @include absolute-center;
            text-align: center;
        }
	}

	@include media-breakpoint-down(xs)
	{
		.showcase-slider-block {
			.play {
				svg {
					width: 30px;
				}
			}
		}
	}
}

.facilities-wrapper{
    margin-top: 120px;
    height: 500px;
	@include position;
	background: $white-color;

    &:before{
        content: '';
        @include position(absolute, 2, $bottom:0, $left:0);
        background-color: $brand-gray-02;
        width: 100%;
        height: 303px;
        @include media-breakpoint-down(md) {
            background-color: $white-color;
            height: auto;

        }
    }

    .facilities-inner-wrapper{
        @include position;
	}

	@media (max-width: 1500px) {
		height: 380px;
		&:before{
			height: 200px;
		}
	}

    @include media-breakpoint-down(lg) {
		height: auto;
		&:before{
			height: auto;
		}
	}
    @include media-breakpoint-down(sm) {
        margin-top: 60px;
    }
}

.facilities {
    @include display-inline-flex(center, space-between);
    align-items: flex-start;

    @include media-breakpoint-down(lg) {
		@include flex-wrap(wrap);
		@include justify-content(space-around);
		// margin-bottom: 20px;

	}
    @include media-breakpoint-down(md) {
        // @include flex-direction(column);
    }

    h4 {
		@include font-p(rem-calc(26px), $weight-medium, 1.1, $brand-primary);
		margin-top: 74px;
		margin-right: 20px;
        // margin-right: 193px;
        flex-grow: 2;
        @include media-breakpoint-down(lg) {
            text-align: center;
            width: 100%;
            margin: 20px auto;
        }
    }

    .facility-block{
        @include max-width(296px);
        flex-grow: 1;
        margin: 0 10px;
        border-radius: 5px;
        overflow: hidden;

        @include media-breakpoint-down(lg) {
			@include max-width(45%);
			margin: 10px;
		}
        @include media-breakpoint-down(md) {
            // @include max-width(50%);
        }

        @include media-breakpoint-down(sm) {
            @include max-width(100%);
        }

        &:hover, &:active, &:focus {
            @include box-shadow(0 16px 30px 0 rgba($brand-gray-06, 0.4));

            .inner-text{
                @include opacity(1);
            }
        }

        h6{
            @include font-s-18($weight-regular, 1.42, $brand-primary);
            width: 100%;
            text-align: center;
			margin-bottom: 40px;

			@media (max-width: 1500px) {
				margin-bottom: 20px;
			}
            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
            }
        }

        .inner-text{
            background-color: $white-color;
            text-align: center;
            padding: 40px 30px;
            @include opacity(0);
            @include font-p-16($weight-regular, 1.5, $brand-primary);
            @include transition;

            @include media-breakpoint-down(md) {
                @include opacity(1);
                -webkit-box-shadow: 0 10px 6px 0 rgba(0, 0, 0, 0.04);
                box-shadow: 0 10px 6px 0 rgba(0, 0, 0, 0.04);
                margin-bottom: 20px;
            }
        }
    }

}

.table-view-wrapper{
    @include position;

    .table-title-bar{
        @include display-inline-flex(center, space-between);
        margin-bottom: 62px;
    }
    h2{
        @include font-p-32($weight-medium, 1.06, $brand-primary);
    }

    .btn-outlined-primary{
        border-radius: 100px;
        border: solid 2px $brand-primary;
        padding: 8px 24px;
        text-transform: initial;
        letter-spacing: 0;
        font-family: $font-primary;
        font-size: 17px;
        font-weight: $weight-regular;
    }


    .btn-outline-danger{
        svg {
            path, line {
                stroke: $brand-danger;
            }
        }

        &:hover, &:active, &:focus {
            svg {
                path,
                line {
                    stroke: $white-color;
                }
            }
        }
    }

    .table{
        thead{
            th{
                border: 0;
                text-align: center;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                @include font-sub($font-xs, $weight-regular, 1.2, $brand-primary);

                .order{
                    float: right;
                }
            }
        }

        td{
            padding-top: 30px;
            padding-bottom: 30px;
            text-align: center;
            vertical-align: middle;
            @include font-p(17px, $weight-regular, 1, $brand-primary);

            &.disabled{
                color: $brand-gray-01;
            }

            &.price {
                font-size: $font-lg;
                font-weight: $weight-medium;
            }
        }


        .tbl-link{
            @include font-p(17px, $weight-medium, 1.47, $brand-primary);
            text-align: center;
            border-bottom: 2px solid transparent;
        }

        th {
            vertical-align: initial;
            border-top: 1px solid transparent;
            padding-top: 20px;
            padding-bottom: 20px;
        }
        td {
            border-top: 0px solid transparent;
        }
    }

    .table-hover{
        tbody{
            tr{
                border-top: 1px solid $brand-gray-03;
                border-bottom: 1px solid $brand-gray-03;
                border-left: 5px solid $brand-gray-02;
                padding: 30px 0;
                @include transition;

                &:hover, &:active, &:focus {
                    // border-bottom: 1px solid transparent;
                    border-top: 1px solid transparent;
                    border-bottom: 1px solid transparent;
                    border-left: 5px solid $brand-danger;
                    //background-color: rgba($brand-danger, 0.05);
                    background-color: $white-color;

                    @include box-shadow(0px 0px 20px 5px rgba($brand-primary, 0.05));

                    td{
                        border-top: 1px solid $white-color;


                        .btn-outlined-primary{
                            background-color: $brand-primary;
                            border: solid 2px $brand-primary;
                            color: $white-color;
                        }

                        .tbl-link{
                            border-bottom-color: $brand-danger;
                        }
                    }
                }

                &.disabled {
                    // border-top: 1px solid rgba($brand-danger, 0.05);
                    background-color: rgba($brand-danger, 0.05);

                    td {
                        border-top: 0px solid rgba($brand-danger, 0.05);
                    }

                    &:hover, &:active, &:focus {
                        td {
                            border-top: 1px solid rgba($brand-danger, 0.05);
                        }
                    }
                }

                &:last-child {
                    border-bottom: 1px solid transparent;
                }
            }
        }
    }


    .btn-large-danger-wrapper {
        @include absolute-center(x) {
            top: auto;
            bottom: -50px;
        }
        @include media-breakpoint-down(md) {
            @include reset-position;
            text-align: center;
            margin: 10px auto;
        }
    }

    @include media-breakpoint-down(sm) {
		padding-bottom: 0px;
		.table-title-bar{
			@include flex-direction(column);
			@include justify-content(center);
			h2 {
				margin-bottom: 30px;
			}
		}

		.table-wrapper {
			margin-bottom: 40px;
		}
	}

	@include media-breakpoint-down(xs)
	{

	}
}

.locality-wrapper {
    padding-top: 172px;

    .location-bar {
        @include justify-content(space-between);

        .lblock {
            h3, .primary-text {
                font-weight: $weight-medium;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: 20px;

        .location-bar {
			padding-bottom: 60px;
        }
	}

	@include media-breakpoint-down(xs) {
		.location-bar {
			padding-bottom: 60px;
			@include align-items(flex-start);
			.lblock {
				width: 100%;
				text-align: left;
				margin: 20px 0;
				@include display-inline-flex(center, flex-start);

				svg {
					margin: 0 15px 0 0;
				}

				&-inner {
					@include flex(1 1 auto);
				}
				.gray-mini-text {
					text-align: left;
					margin-bottom: 5px;
				}
			}
        }
	}
}

.location-bottom-info {
	@include display-inline-flex(center, space-between);
	background-color: $white-color;
    .pimg-wrapper {
        width: 100%;
        height: 535px;
        @include background-defaults;
		border-top-right-radius: 535px;

		img {
			display: none;
		}
    }

    .container {
        margin: 0 auto;
    }

    .location-info {
        max-width: 612px;
        margin-left: auto;
        padding-left: 0;
    }

    @include media-breakpoint-down(md) {
		display: block;
		height: auto;
        .pimg-wrapper {
            width: 100%;
            border-radius: 0;
			margin-bottom: 20px;
			height: auto;
			background-image: none !important;

			img {
				display: block;
			}
        }
        .location-info {
            max-width: 100%;
        }
    }
}

.property-plan-wrapper {
    height: auto;

    // .documentation-wrapper .text-wrapper {
    //     @include position(relative, 2, auto, auto, auto, auto);
    // }
}

.project-single-page {

	.parallax-bg-container{
		@include background-defaults;
		@include position(fixed, -2, 0, 0, 0, 0);
		@include transition(all 0.1s linear);
		height: 100vh;
	}
	@include media-breakpoint-down(md)
	{
		.banner-container {
			height: 750px;
			// .banner-wrapper {
			// 	height: auto;
			// 	@include reset-position;
			// }
		}

		// .banner-footer {
		// 	height: auto;
		// 	@include reset-position;
		// 	width: 100%;
		// }
	}
}
