/*----------  Container  ----------*/

@mixin extend-container($width: 1140px, $gutter: 30)
{
	$padding: $gutter / 2;
	
	width: 100%;
	padding-right: $padding;
	padding-left: $padding;

	@if $width == auto
	{
		max-width: auto;
	}
	@else if $width == 100%
	{
		max-width: 100%;
	}
	@else
	{
		max-width: $width + $gutter;
	}
}