/*----------  Footer  ----------*/

footer
{
	@include position;

	background-color: $brand-primary;
	padding: 100px 0 0;

	.footer-inner-wrapper{
        @include display-inline-flex(flex-start, space-between);
        @include position;
		// flex-wrap: wrap;

		.col-footer-brand {
			min-width: 170px;
		}

        @include media-breakpoint-down(md) {
            display: block;
        }

        .sub-footer-bar {
            @include position(absolute, 2, $left: 50%, $bottom: 0);
			@include transform(translateX(-50%));
			width: 100%;
        }


        @include media-breakpoint-down(lg) {
            .sub-footer-bar {
                @include reset-position;
                @include transform(none);
            }
        }
	}

	.footer-text-wrapper{
        padding-top: 40px;
        height: 100%;
        @include display-flex(column, center, space-between);
        width: 100%;
        padding-left: 150px;

        .col-footer-wrapper {
            @include display-inline-flex(flex-start, space-between);
            width: 100%;
        }

		@media (max-width: 1439px)
		{
            padding-left: 50px;
        }

        @include media-breakpoint-down(lg) {
            .col-footer-wrapper {
                @include flex-wrap(wrap);
                .col-footer {
                    width: 50%;
                    margin-bottom: 50px;

                    .footer-text-container {
                        @include align-items(center);
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            padding-left: 0;
        }

        @include media-breakpoint-down(xs) {
            .col-footer-wrapper {
                .col-footer {
                    width: 100%;
                    margin-bottom: 30px;
                }
            }
        }
	}

	.logo{
		margin-bottom: 80px;
		svg{
			@include max-width(140px);
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 0;
        }
	}

	p {
		@include font-s($font-sm, $weight-light, 1.43, $white-color);
		margin-bottom: 14px;
	}

	.col-footer{

        .footer-text-container {
            @include display-flex(column, flex-start, space-between);
        }

		@include media-breakpoint-down(md) {
			text-align: center;

			.btn-outline-danger{
				@include max-width(400px);
				margin: 24px auto;
			}

		}

		.footer-link{
			display: inline-block;
			@include font-sub($font-sm, $weight-regular, 1.57, $white-color);
			svg{
				margin-right: 14px;
			}
		}
		.btn{
            margin-bottom: 40px;
            display: inline-block;
		}

		.heading{
			@include font-sub($font-xs, $weight-regular, 1.67, $white-color);
			letter-spacing: 1.2px;
			text-transform: uppercase;
			margin-bottom: 14px;

			svg{
				margin-right: 16px;
			}
        }

        .sub-text {
            margin-bottom: 18px;
			span{
				@include font-s($font-xs, $weight-light, 1.43, $brand-gray-01);
				text-indent: 30px;
				display: block;
			}
		}

		.text
		{
            margin-bottom: 24px;
			span{
				@include font-sub($font-sm, $weight-regular, 1.67, $white-color);
				display: block;
                text-indent: 0;

                strong {
                    font-weight: $font-primary;
                    font-weight: $weight-bold;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
		}
	}

	.sub-footer-bar{
		@include display-inline-flex(top);
	}

	.social-icon-bar{
		text-align: center;
		@include flex(1);

		.social-links{
			list-style-type: none;
			margin-top: 25px;
			li{
				display: inline-block;
				margin: 0 10px;
			}
		}

		.footer-links{
			list-style-type: none;
			margin-top: 70px;
			li{
				display: inline-block;

				a{
					@include font-sub($font-sm, $weight-regular, 1.33, $white-color);
                    margin: 0 25px;
                    letter-spacing: 1.2px;
                    text-transform: uppercase;

                    &:hover, &:active, &:focus
                    {
                        color: $brand-danger;
                    }
				}
			}
        }

        @include media-breakpoint-down(sm) {
            .footer-links{
                li{
                    margin-bottom: 10px;
                }
            }
        }
	}


	.bottom-text-wrapper{
		text-align: center;
		padding-top: 60px;
		margin-top: 60px;
		border-top: 1px solid rgba($brand-gray-05, 0.2);

		@include position;

		.bottom-menu {
			@include display-inline-flex(center, center);
			@include list-unstyled;
			margin-top: 20px;

			li {
				padding: 0 10px;
				@include position;
				&:after {
					content: '|';
					color: $white-color;
					@include position(absolute, 2, $right: 0);
				}

				&:last-child {
					&:after {
						content: '';
					}
				}
			}
		}

		p {
            @include font-sub(11px, $weight-regular, 1.64, rgba($white-color,0.5));
            @include max-width(1100px);
            margin-bottom: 0;
        }

        a {
            color: $white-color;
            &:hover, &:active, &:focus
            {
                color: $brand-danger
            }
        }

		.footer-brand-logo{
			padding: 15px;
			background-color:#19447a;

			@include position(absolute, 2, $top:0, $right:0);
			@include max-width(170px);
			@include border-radius(100%);
			@include transform(translateY(-50%));


			@include media-breakpoint-down(md) {
				@include reset-position;
				margin: 20px auto;
			}
        }

		@media (max-width: 1439px)
		{
            padding-top: 100px;
        }
	}

	.copywrite-container{
		@include display-inline-flex(center, space-between);
		margin-top: 70px;

		p{
			@include font-sub(12px, $weight-light, 3.17, rgba($white-color,0.5));
		}

		a{
            color: $white-color;
            &:hover, &:active, &:focus
            {
                color: $brand-danger
            }
		}

	}

}
