/*----------  Typography  ----------*/
//heading classes
h2{
    // @include font-s(46px, $weight-light, 1.22, $brand-primary);
}
h3 {
    @include font-s-46($weight-medium, 1.22, $brand-primary);
}

.mini-primary-text p, p.mini-primary-text{
    @include font-p-16($weight-regular, 1.5, $brand-primary);
    margin-bottom: 24px;
}



.primary-mini-link {
    @include font-sub($font-xs, $weight-light, 1.33, $brand-primary);
    text-transform: uppercase;
    letter-spacing: 1.2px;

    &:hover{
        color: $brand-danger;
    }
}

.gray-mini-link {
    @include font-s-12($weight-light, 1.33, $brand-gray-01);
    text-transform: uppercase;
    letter-spacing: 1.2px;
}

.gray-mini-text{
    @include font-sub($font-xs, $weight-regular, 1.4, $brand-gray-01);
    text-transform: uppercase;
}

.white-mini-primary-text{
    @include font-p-20($weight-regular, 1.63, $white-color);
}

.white-mini-primary-text-16{
    @include font-p-16($weight-regular, 1.63, $white-color);
}

