.modal-content {
    background-color: $brand-gray-02;
    border: none;
    border-radius: 0;
	padding: 30px 158px;

	fieldset {
		margin: 0;
	}

    .modal-header {
        text-align: center;
        border: 0;
        border-radius: 0;
        padding: 0;
        margin-bottom: 25px;


        .close {
            opacity: 1;
            @include position(absolute, 999, $top:48px, $right:48px);

            svg {
                rect {
                    fill: $brand-danger;
                }
            }
        }

        .header-wrapper {
            width: 100%;
        }

        h4 {
            @include font-s(46px, $weight-medium, 1.22, $brand-primary);
        }

        p {
			@include font-p(22px, $weight-light, 1.33, $brand-gray-01);
			line-height: 45px;
		}

		.modal-sub-title {
			padding-top: 20px;
		}
    }

    .modal-body {
        padding: 0;

        .info-bar-wrapper {
            @include position;

            .info-bar {
                height: 60px;
                border-radius: 6px;
                border: 1px solid $brand-gray-03;
                @include display-inline-flex(center, space-between);

                .label-wrapper {
                    @include display-inline-flex(center, space-between);
                    @include flex(1 1 auto);
                    &:first-child {
                        max-width: 150px;
                    }
                }

                .info-bar-text {
                    padding: 20px 24px;
                    text-align: center;
                    @include font-p($font-base, $weight-light, 1.19, $brand-gray-01);
                }

                .form-group {
                    margin-bottom: 0;
                    width: 45%;

                    .bootstrap-select {
                        background: $brand-gray-02;
                        .btn {
                            border: 0px !important;
                        }
                        .dropdown-toggle .filter-option {
                            text-align: center;
                        }
                    }
                }

                .sep {
                    color: $brand-gray-01;
                    min-width: auto;
                }
            }
        }
    }

    .modal-footer {
        border: 0;
        border-radius: 0;
        padding: 0;
		padding-top: 20px;
		@include flex-direction(column);
        @include justify-content(center);
    }

    .agreement-wrapper {
        margin-top: 20px;
        .toggle {
            z-index: 0;
        }
    }
    @include media-breakpoint-down(lg) {
        padding: 80px;
        .modal-header {
            h4 {
                font-size: rem-calc(32px);
            }

            p {
                font-size: $font-lg;
            }
        }

        input,
        textarea,
        select {

            &.form-control {
                padding: 15px 20px;
            }
        }

        .modal-body {
            .info-bar-wrapper {
                .info-bar {
                    height: auto;
                    .info-bar-text {
                        padding: 15px 24px;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        padding: 40px;
        .modal-header {
            margin-bottom: 30px;
            .close {
                top: 14px;
                right: 14px;
                padding: 0;
                margin: 0;
            }

            h4 {
                font-size: rem-calc(24px);
                margin-bottom: 10px;
            }

            p {
				font-size: $font-base;
            }
        }

        input,
        textarea,
        select {

            &.form-control {
                padding: 10px 20px;
            }
        }

        .modal-body {
            .info-bar-wrapper {
                .info-bar {
                    .info-bar-text {
                        padding: 10px 24px;
                    }
                }
            }
		}

		.modal-footer {
			padding-top: 0;
		}
    }
    @include media-breakpoint-down(xs) {
        padding: 20px;
        .modal-body {
            .info-bar-wrapper {
                .info-bar {
                    @include flex-direction(column);
                    .info-bar-text {
                        width: 100%;
                    }
                    .info-label {
                        text-align: center;
                        .sep {
                            display: none;
                        }
                    }
                    .label-wrapper, .label-wrapper:first-child {
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

.rentModal{
    padding-top: 50px;  //to show the close button
    .modal-content {
        background-color: #f9fafc;
        border: none;
        border-radius: 5px;
        padding: 0;

        .modal-header{
            margin: 0;
            @include position;

            h4{
                @include font-s-24($weight-medium, 1.22, $brand-primary);
                margin: 40px auto;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 6.24px;
            }

            .close{
                padding: 0;
                @include absolute-center(x){
                    top: -20px;
                }


                svg {
                    rect {
                        fill: $white-color;
                    }
                }
            }

        }

        .modal-body{
            .logo-wrapper{
                @include max-width(326px);
                margin: 40px auto;
            }

            .modal-text-wrapper{
                background-color: $brand-primary;
                height: 100%;
                text-align: center;
                padding: 20px 0 40px;
                margin-right: -1px;
                @include position;

                &:before{
                    content: '';
                    height: 60px;
                    width: 100%;
                    background-color: $brand-primary;
                    @include position(absolute, 2, -60px, 0);
                }

                .date{
                    @include font-s-32($weight-medium, 1.38, $white-color);
                    letter-spacing: 3.2px;
                }

                .time{
                    @include font-s-16($weight-regular, 1.38, $brand-gray-01);
                    letter-spacing: 1.6px;
                    margin-bottom: 40px;
                }

                .location-wrapper{
                    @include display-inline-flex(center, center);
                    margin-bottom: 43px;

                    .location{
                        @include font-s-12($weight-regular, 1.33, $white-color);
                        text-transform: uppercase;
                        letter-spacing: 1.2px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .modal-content {
            .modal-header{
                h4{
                    letter-spacing: 1.3px;
                }
            }
            .modal-body{
                .logo-wrapper{
                    @include max-width(250px);
                    margin: 30px auto;
                }

                .modal-text-wrapper{
                    @include border-radius(0 0 5px 5px);
                    margin-right: 0;
                    &:before{
                        display: none;
                    }
                }
            }
        }
    }
}

.modal-xl {
    @media (min-width: 768px) {
        max-width: 767px;
    }
    @media (min-width: 1200px) {
        max-width: 1086px;
    }
}

.modal-xlarge {
    .modal-xl {
        @media (min-width: 1200px) {
            max-width: 1244px;
        }
    }
}

.modal-backdrop, .lightboxOverlay {
	background-color: #0d1354;
	@include opacity(0.8);

	&.show {
		@include opacity(0.8);
	}
}

.video-modal {
	iframe {
		@include media-breakpoint-down(sx)
		{
			height: 80vh;
		}
	}
}

.visit-video-modal {
	.modal-content {
		padding: 0;
	}
}
