.page{
    .inner-page-banner{
        @include position;
        height: 730px;

        .container{
            @include position;
            height: 100%;
        }

        .banner-bottom-decoration{
            @include position;
            @include transform(translateY(-99%));

            @include media-breakpoint-down(md) {
                @include reset-position;

            }
        }
    }
}

.inner-page-banner{

    .banner-bottom-decoration{
        // @include position(absolute, 9, $bottom:-150px, $left:0);
        height: 150px;
        width: 100%;

        .decoration-before{
            content: '';
            @include position(absolute, -1, $bottom:0px, $left:0);
            height: 150px;
            width: 100%;
            background-repeat: no-repeat;
        }
        .decoration-after{
            content: '';
            @include position(absolute, -1, $bottom:0px, $left:789px, $right:0);
            height: 150px;
            background-color: $white-color;

        }
    }
}

.page-team-main{
    .inner-page-banner{
		@include position(relative, 0);

		&:before {
            background-color: rgba(13, 19, 84, 0.55);;
		}

        .container{
            @include position(static);
            height: 100%;
        }

        .btn-youtube {
            @include absolute-center;
            font-size: 60px;
            color: $brand-danger;

            &:hover, &:active, &:focus {
                color: $white-color;
            }
        }
    }

    .banner-bottom-decoration{
        // @include position(absolute, 9, $bottom:-150px, $left:0);
        @include position;
        @include transform(translateY(-99%));

        @include media-breakpoint-down(md) {
            @include reset-position;

        }
        height: 150px;
        width: 100%;

        .decoration-before{
            content: '';
            @include position(absolute, -1, $bottom:1px, $left:0);
            height: 150px;
            width: 100%;
            background-repeat: no-repeat;
        }
        .decoration-after{
            content: '';
            @include position(absolute, -1, $bottom:-1px, $left:789px, $right:0);
            height: 151px;
            background-color: $white-color;

        }
    }

    .page-banner-container {
		@include background-defaults;
        .banner-outer-wrapper {
            @include position(absolute, 2, auto, 270px, auto, 80px);
            @include transform(translate(0));
            width: auto;
        }
        .banner-wrapper {
            margin-top: 0;
        }
    }

    @include media-breakpoint-down(lg) {
    }
    @include media-breakpoint-down(md) {
        .banner-bottom-decoration {
            .decoration-before { display: none; }
            .decoration-after { width: 100%; left: 0; }
            height: auto;
        }
        .inner-page-banner{
            height: 100vh;
            // height: auto;
            .container{
                @include position(relative);
                height: auto;
            }
        }

        .page-banner-container {
            @include display-flex(column, center, center);
            h1 {
                font-size: rem-calc(64px);
                margin-bottom: 0;
			}

			.action-wrapper{
				text-align: center;
			}
			.btn-youtube {
				@include reset-position;
				font-size: auto;
			}
            .banner-outer-wrapper {
                @include reset-position;
                text-align: center;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .page-banner-container {
            h1 {
                font-size: $font-xl;
            }
        }
    }
}

.team-contact{
    margin-bottom: 128px;

    .team-contact-wrapper{
        margin-left: auto;
        margin-right: 0;
        @include display-inline-flex(center, space-between);
        @include max-width(800px, false);

        @include media-breakpoint-down(lg) {
            margin-left: 0;
            @include max-width(800px, true);
        }

        @include media-breakpoint-down(md) {
            @include flex-wrap(wrap);
        }
    }

    .contact-block{
        margin: 20px 0;
        text-align: center;

        img{
            @include max-width(138px);
            margin-bottom: 20px;
            height: 138px;
            border-radius: 100%;
        }
    }

    .contact-name{
        @include font-sub($font-xs, $weight-regular, 1.4, $brand-gray-01);
        text-transform: uppercase;
        letter-spacing: 1.2px;

        margin-bottom: 14px;
    }

    .contact-number{
        @include font-p-20($weight-regular, 1.4, $brand-primary);
    }


    @media (max-width: 1750px) {
		.team-contact-wrapper{
            max-width: 55%;
        }
	}

    @media (max-width: 1650px)
    {
        @include position(absolute, 2, $top: 0, $right: 0, $left: 650px);
		width: auto;
        .team-contact-wrapper{
            @include max-width(100%, true);
        }
    }

	@include media-breakpoint-down(lg) {
        @include position(absolute, 2, $top: 100%, $right: auto, $left: auto);
		width: 100%;
	}

    @include media-breakpoint-down(md) {
        @include reset-position;
        margin-top: 40px;
    }

    @include media-breakpoint-down(md) {
		margin-bottom: 30px;
        .team-contact-wrapper {
            @include flex-wrap(wrap);
            @include justify-content(center);

        }
        .contact-block{
            width: 150px;
            margin: 20px;
            @include flex(1 1 auto);
        }
    }
}

.team-members{
    margin-top: 120px;

    .member-blocks-wrapper {
        >.row {
            @include justify-content(center);
        }
    }

    h3{
        text-align: center;
        @include font-p-32($weight-medium, 1.06, $brand-primary);
        margin-bottom: 60px;
    }

    .member-block{

        @include position;
        margin: 20px 0;


        &:hover{
            .overlay-text{
                @include opacity(1);
                bottom: 0;
            }
        }

        .image-wrapper{

        }

        .overlay-text{
            width: 100%;
            @include position;
            @include opacity(0);
            @include transition;

            @include position(absolute, 2, $left:0, $bottom:0);

            &:before{
                content: '';
                @include position(absolute, -1, 0, 0);
                height: 100%;
                width: 100%;
                object-fit: contain;

                background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 25%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 25%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 25%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
            }



            .name{
                @include font-p-20($weight-light, 1.4, $brand-primary);
                margin-top: 20px;
                margin-bottom: 10px;
                text-align: center;

                span{
                    font-weight: $weight-medium;
                }
            }

            .designation{
                @include font-s-12($weight-light, 1.4, $brand-gray-01);
                text-align: center;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                margin-bottom: 24px;
            }

            a{
                @include font-p(15px, $weight-light, 1.4, $brand-danger);
                margin-bottom: 10px;
                text-align: center;
                width: 100%;
            }

		}

		@include media-breakpoint-down(xs) {
			.overlay-text {
				@include opacity(1);
				bottom: -2px;
			}
		}
    }

    .team-footer-text{
        text-align: center;

        @include font-p-32($weight-medium, 1.06, $brand-primary);

        a{
			@include font-p-32($weight-medium, 1.06, $brand-danger);
			@include position;

			&:after {
				content: '';
				height: 3px;
				background-color: $brand-primary;
				@include transition;
				width: 0;
				@include position(absolute, 4, $bottom: -2px, $left: 0);
			}

			&:hover, &:active, &:focus
			{
				color: $brand-primary;
				&:after {
					width: 100%;
				}
			}
        }

	}

    @media (max-width: 1650px) {
		margin-top: 0px;
	}

	@include media-breakpoint-down(lg) {
		margin-top: 120px;
	}
	@include media-breakpoint-down(sm) {
		margin-top: 0;

		h3 {
			margin-bottom: 20px;
		}
	}
}
