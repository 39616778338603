.page-services-main {
	.page-banner-container {
		// height: 100vh;
		@include display-flex(column, flex-start, center);
        width: 100%;

		.bottom-decoration {
			width: 100%;

			.decoration-left, .decoration-right {
				background-color: #f9fafc;
			}
        }

        .image-wrapper {
            width: 50%;
		}

		.image-mobile-wrapper {
			display: none;
		}

        .banner-video {
            @include border-radius(6px);
        }
	}

	.review-container{
        @include position(absolute, 2, $bottom: 0);
        width: 100%;
		.bottom-decoration {
			position: absolute;
			width: 100%;
			// @include transform(translateY(-100%));

			.decoration-left, .decoration-right {
				background-color: $brand-gray-02;
			}
		}

		.review-wrapper {
			@include position;
			height: 150px;
			max-width: 1200px;
			// @include transform(translateY(-100%));
		}
    }

    .service-container {
        .gray-wrapper {
            padding-top: 120px;
        }
    }

	.fluid-media-wrapper {
		@include position;
		.container {
			@include position(static);
			height: 100%;
			.row, .cs-col { @include position(static); width: 100%; height: 100%;}
		}
		.inner-content-block {
			height: 100%;
			@include display-flex(column, center, center);
		}
		.inner-content-wrapper {
			height: 541px;
			@include position(static);
			@include display-flex(column, flex-start, center);
		}
		.inner-media-block {
			@include position(absolute, 2, 50%, 0, 50%, auto);
			height: 100%;
			@include transform(translateY(-50%));

			.twentytwenty-container {
				// height: 541px !important;

				img {
					height: 100%;
				}
			}
		}

		&.reverse {
			.inner-media-block {
				left: 50%;
				right: 0;
			}
		}
	}

	.contact-form-container {
        .btn-theme-gray {
            @include max-width(296px);
        }
	}


	@media (min-width: 1200px)
	{
		.fluid-media-wrapper {
			.inner-media-block {
				// @include reset-position;
				.twentytwenty-container {
					height: 400px !important;
				}
			}
			// &.reverse {
			// 	.inner-media-block { @include reset-position; };
			// }
		}
	}

	@media (min-width: 1500px)
	{
		.fluid-media-wrapper {
			.inner-media-block {
				.twentytwenty-container {
					height: 541px !important;
				}
			}
		}
	}

	@media (max-width: 1499.98px)
	{
		.fluid-media-wrapper {
			>.container {
				@include reset-position;
				.row, .cs-col { @include reset-position; }
			}
			.inner-content-wrapper {
				height: auto;
				// padding: 60px 0;
			}
			.inner-media-block {
				@include reset-position;
				.twentytwenty-container {
					// height: 400px !important;
				}
			}
			&.reverse {
				.inner-media-block { @include reset-position; };
			}
		}
	}

	@include media-breakpoint-down(lg) {
		.page-banner-container {
			.image-wrapper, .text-wrapper {
                @include flex(1);
                width: 100%;
			}
			.sub-title {
				margin-bottom: 20px;
			}
			.banner-outer-wrapper {
				width: 100%;
				padding: 0 15px;
			}
		}
		.inner-content-wrapper {
			.text-wrapper, .text-wrapper.right-block{
				max-width: 100%;
				margin-bottom: 30px;
			}
		}

		.fluid-media-wrapper {
			&.mb-80 {
				margin-bottom: 0;
			}
			.inner-media-block {
				margin-bottom: 30px;
				border-radius: 0px;
				.twentytwenty-container {
					// height: 500px !important;

					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.page-banner-container {
			padding: 150px 0 100px;
			height: auto;
			.banner-wrapper {
				@include flex-direction(column);
				margin-top: 80px;
			}
			.image-wrapper {
				display: none;
			}
			.image-mobile-wrapper {
				display: block;
				margin-bottom: 20px;
			}
			.image-wrapper, .text-wrapper {
				text-align: center;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.page-banner-container {
			// height: auto;
			.banner-outer-wrapper {
				@include reset-position;
			}
			.banner-wrapper {
				margin-top: 0px;
			}
		}
		.review-container{
			.review-wrapper {
				max-width: 100%;
				// @include transform(none);
				@include flex-direction(row);
				// height: auto;
				.review-block {
					@include flex-direction(column);
					height: auto;
					text-align: center;
					.review-logo {
						margin-right: 0;
						margin-left: 0;
						margin-bottom: 10px;
						max-width: 100px;
					}

					&.inverse {
						.review-bar {
							text-align: center;
							@include order(2);
						}
					}
				}
			}
		}


		.fluid-media-wrapper {
			.inner-media-block {
				.twentytwenty-container {
					// height: 500px !important;
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {

		.page-banner-container {
			padding-bottom: 0;
			.banner-outer-wrapper {
				margin-bottom: 50px;
				padding: 0;
			}
		}
		.service-container {
			.gray-wrapper {
				padding-top: 30px;
			}
		}

		.fluid-media-wrapper
		{
			.inner-content-wrapper {
				>.container, .row, .cs-col {
					padding: 0;
					margin: 0;
				}

				.inner-content-block {
					padding: 0 15px;
				}
				.inner-media-block {
					@include border-radius(0);
					padding: 0;
				}
			}
			margin-bottom: 40px;
		}
		.review-container{
			@include reset-position;
			background-color: #f9fafc;
			bottom: -2px;
			.bottom-decoration {
				display: none;
			}
			.review-wrapper {
				@include transform(none);
				@include justify-content(space-around);
			}
		}

	}
}
