
.preloader {
	display:inline-block;
	font-size:0px;
	padding:0px;
	margin-top: 30px;
}

.preloader span {
	vertical-align:middle;
	border-radius:100%;

	display:inline-block;
	width:10px;
	height:10px;
	margin:3px 2px;
	-webkit-animation:preloader 0.8s linear infinite alternate;
	animation:preloader 0.8s linear infinite alternate;
}
.preloader span:nth-child(1) {
	-webkit-animation-delay:-1s;
	animation-delay:-1s;
	background:rgba(245, 103, 115,0.6);
}
.preloader span:nth-child(2) {
	-webkit-animation-delay:-0.8s;
	animation-delay:-0.8s;
	background:rgba(245, 103, 115,0.8);
}
.preloader span:nth-child(3) {
	-webkit-animation-delay:-0.26666s;
	animation-delay:-0.26666s;
	background:rgba(245, 103, 115,1);
}
.preloader span:nth-child(4) {
	-webkit-animation-delay:-0.8s;
	animation-delay:-0.8s;
	background:rgba(245, 103, 115,0.8);

}
.preloader span:nth-child(5) {
	-webkit-animation-delay:-1s;
	animation-delay:-1s;
	background:rgba(245, 103, 115,0.4);
}

@keyframes preloader {
	from {transform: scale(0, 0);}
	to {transform: scale(1, 1);}
}
@-webkit-keyframes preloader {
	from {-webkit-transform: scale(0, 0);}
	to {-webkit-transform: scale(1, 1);}
}

.preloader-wrapper {
	background-color: $brand-primary;
	@include position(fixed, 9999, 0, 0, 0, 0);
	@include display-flex(column, center, center);
	.preloader-container {
		@include display-flex(column, center, center);
	}
}
