.projects-page {
    @include media-breakpoint-down(md) {
		background-color: $brand-gray-02;
	}
}
.showcase-container {
	padding-top: 20px;
}
.showcase-wrapper {
    margin: 0 0 80px;

    .row {
        @include display-inline-flex(center, center);
    }

    .property-block {
        margin: 0;
    }

    .property-info-block {
		display: block;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: $white-color;
		overflow: hidden;
		@include box-shadow(0 16px 30px 0 rgba(213, 215, 233, 0.4));

        .property-name {
            height: 80px;
            // background-image: url('../images/properties/p-info-bg.png');
            background-color: rgba($color: #000, $alpha: 0.4);
            @include background-defaults;
            @include position;

            &:before {
                content: '';
                background-color: rgba($color: #000, $alpha: 0.4);
                @include position(absolute, 2, 0, 0);
                width: 100%;
                height: 100%;
			}

            h2 {
                text-transform: uppercase;
                @include font-s-18($weight-medium, 1.3, $white-color);
                letter-spacing: 1.08px;
                @include absolute-center;
                width: 100%;
                text-align: center;
            }

        }

        .property-info {
            @include display-inline-flex(center, center);

            .info {
                padding: 40px;
                text-align: center;
                @include position;

                svg {
                    @include max-width(18px);
                    height: 22px;
                }

                .text {
                    @include font-s-20($weight-regular, 1.4, $brand-primary);

                    small {
                        @include font-s-12($weight-regular, 1.4, $brand-gray-01);
                    }
                }

                &.sep {
                    &:before {
                        content: '';
                        height: rem-calc(40px);
                        width: 1px;
                        background-color: $brand-gray-03;

                        @include absolute-center(y) {
                            left: auto;
                            right: 0;
                        }
                    }
                }
            }
        }

        .property-location {
            // text-align: center;
            @include display-inline-flex(center, center);
            margin-bottom: 40px;

            p {
                @include font-s-12($weight-regular, 1.11, $brand-gray-01);
                letter-spacing: 1.2px;
                text-transform: uppercase;
                margin-left: 5px;
            }
        }

        .info-footer {
            height: 160px;
            @include display-inline-flex(center, space-between);
            background-color: $brand-primary;

            padding: 0 60px;

        }
    }

    @include media-breakpoint-down(md) {
		background-color: $white-color;
		@include box-shadow(0 10px 10px 0 rgba($black-color, 0.04));
		@include border-radius(10px);
		overflow: hidden;

        .row {
            @include flex-wrap(wrap);
        }

        .property-info-block{
            margin: 0px;
			@include position;

			.property-name {
				background: $white-color;
				background-image: none !important;
				padding-top: 60px;
				height: auto;

				h2 {
					color: $brand-primary;
					@include reset-position;
				}

				&:before {
					display: none;
				}
			}
			.property-info {
				.info {
					padding: 20px;
				}
			}

			.info-footer {
				// height: auto;
				// padding-bottom: 20px;
				// background-color: $white-color;

				.btn {
					// border-color: $brand-primary;
					// color: $brand-primary;
					// font-size: $font-base;

					&:hover, &:active, &:focus
					{
						border-color: $brand-danger;
						color: $white-color;
					}
				}
			}

			.property-location {
				@include absolute-center(x);
				top: 20px;
				margin-bottom: 0;

				p {
					font-size: $font-base;
				}
			}
		}

		.circle-wrapper .circle-text {
			.percentage, .sold {
				// color: $brand-primary;
			}
		}
    }

    @include media-breakpoint-down(xs) {
        .property-info-block{
			margin: 0px;
			padding: 20px;

			.property-name {
				padding-top: 40px;
				h2 {
					text-align: left;
				}
			}

            .property-info {
                // @include flex-direction(column);
                padding: 10px 0px;
                .info {
					margin-bottom: 10px;

					&:first-child {
						padding-left: 0;
					}
					&:last-child {
						padding-right: 0;
					}

					svg {
						margin-bottom: 10px;
						height: 36px;
						width: 24px;
						max-width: 100%;
					}
					.text {
						font-size: $font-sm;
					}
                }
			}

			.property-location {
				margin-bottom: 0px;
				width: 100%;
				left: 20px;
				@include transform(none);
				@include justify-content(flex-start);
			}

            .info-footer {
				height: auto;
                // @include flex-direction(column);
				padding: 0 20px;
				margin: 0 -20px -20px;
            }

            .circle-wrapper {
                margin-top: 10px;
            }
        }
    }
}

.showcase-slider-block {

    .p-slide {
        height: 514px;
        border-radius: 5px;
        @include background-defaults;
    }

    .slick-dots{
        @include absolute-center(x) {
            bottom: 40px;
            top: auto;
        }
    }

    &:hover {
        .slick-dots {
            @include absolute-center(x) {
                bottom: 20px;
                top: auto;
            }
        }
    }
    @include media-breakpoint-down(md) {
		.p-slide {
			@include border-radius(10px 10px 0 0);
		}
	}
    @include media-breakpoint-down(xs) {
        .p-slide {
			@include border-radius(5px 5px 0 0);
			height: 320px;
        }
    }
}
