.sales-page-main{
	@include position;
	.property-listing-wrapper {
		@include display-inline-flex(flex-start, center);
        @include flex-wrap(wrap);
        padding: 10px 0;

		.property-block {
			width: 30%;
			max-width: 600px;
			@include flex(1 1 auto);
			margin: 10px;
			@include border-radius(5px);

			.property-slider-wrapper {
				.property-images {
					@include border-radius(5px 5px 0 0);
					overflow: hidden;
				}
			}
		}
	}

	.property-listing-container {
		@include position;
		.property-registration-wrapper {
			@include position(fixed, 4, 50%, auto, 0, auto);
			@include transform(translate(calc(100% - 75px), -50%));
			background-color: $brand-primary;
			padding: 20px 26px 20px 24px;
			@include transition;
			cursor: pointer;
			@include border-radius(999px 0 0 999px);
			@include box-shadow(0 20px 20px 0 rgba($brand-primary, 0.2));

			.property-registration-container {
				@include display-inline-flex(center, flex-start);
				max-width: 288px;

				.icon {
					margin-right: 27px;
				}

				a {
					color: $white-color;
				}
			}

			&:hover
			{
				background-color: $brand-danger;
				@include transform(translate(0, -50%));
			}
		}
	}

	.property-filters-outer {

		.filter-button-wrapper {
			display: none;
		}

		.input-price-range {
			display: flex;
		}

		.select-price-range {
			display: none;
		}

	}

	@media (max-width: 1439px)
	{
        .property-info {
            .name {
                font-size: $font-lg;
            }
        }

        .property-price {
            .price {
                font-size: $font-lg;
            }
        }

		.property-slider-wrapper {
			.slider-overlay {
				.property-details-wrapper {
					padding: 24px;
				}
			}
		}

		.property-sub-info {
			padding: 24px;
			&:before {
				width: calc(100% - 48px);
				background-color: $brand-gray-03;
				@include absolute-center(x);
			}
		}
    }

    @include media-breakpoint-down(lg) {
        .property-listing-wrapper {
            .property-block {
                width: 45%;
            }
		}
	}

	@include media-breakpoint-down(md)
	{
		.property-filters-outer {
			@include position(absolute, 9, $top: 0, $bottom: 0);
			display: none;

			.filter-button-wrapper {
				@include display-flex(column, center, center);
				width: 100%;
			}

			.input-price-range {
				display: none;
			}

			.select-price-range {
				display: flex;
			}

		}
	}

    @include media-breakpoint-down(sm) {
        .property-listing-wrapper {
            @include justify-content(center);
            .property-block {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .property-slider-wrapper {
			@include position;
			height: 350px;
			.slider-overlay {
                @include position(static);
                .property-details-wrapper {
                    // @include reset-position;
                }
                .property-details {
                    display: block;
                }
                .fav-icon {
                    top: 20px;
                    right: 20px;
                }
            }

            .slick-dotted .slick-dots
            {
                bottom: 10px;
            }
        }

        .property-price {
            .price {
                margin-top: 10px;
				margin-left: 0px;
				display: inline-block;
            }
        }
    }
}
